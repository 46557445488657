<template>
  <el-dialog
    v-drag
    title="文件列表"
    :visible.sync="dialogFormVisible"
    width="872px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancer"
    center
    :append-to-body="true"
  >
    <BaseTable
      :height="height"
      :tableData="tableData"
      :isPager="false"
      :border="true"
      :loading="false"
    >
      <template slot="createdOn" slot-scope="row">
        {{ row.scope.createdOn ? row.scope.createdOn.slice(0,16).replace('T', ' ') : '' }}
      </template>
      <template slot="sourceName" slot-scope="row">
        {{ row.scope.fileName.split('.')[1].toUpperCase() }}
      </template>
      <template slot="opt" slot-scope="row">
        <div class="opt">
          <el-button v-if="isView(row.scope.fileName.split('.')[1].toUpperCase())" type="text" @click="viewFile(row.scope)">
            <img style="vertical-align: -4px;" src="@/assets/images/bill/view.png" />
            查看
          </el-button>
          <el-link
            style="margin-top: -2px"
            :underline="false"
            type="primary"
            :href="`/backEnd/api/${config.supplyCode}/File/downLoadFmsFile?mblId=${mblId}&fileId=${row.scope.id}&fileName=${row.scope.fileName}`"
            target="download">
            <img style="vertical-align: -4px;" src="@/assets/images/bill/download.png" />
            下载
          </el-link>
        </div>
      </template>
    </BaseTable>
    <p class="tips">*暂时只支持图片和PDF查看</p>
  </el-dialog>
</template>
  <script>
const BaseTable = () => import('@/components/table/BaseTable')
import config from '@/utils/config'
  export default {
    components: { BaseTable },
    props: {
      dialogFormVisible: {
        type: Boolean,
        default: false
      },
      fileList: {
          type: Array,
          default: function() {
              return []
          }
      },
      mblId: {
        type: [String, Number]
      }
    },
    data() {
      return {
        config,
        height: 360,
        tableData: {
          fileds: [
            {
              filed: 'fileName',
              label: '文件名',
              width: '313',
              resizable: true
            },
            {
              filed: 'createdOn',
              label: '上传时间',
              width: '140',
              isCustom: true,
              resizable: true
            },
            {
              filed: 'createdName',
              label: '上传人',
              width: '100',
              resizable: true
            },
            {
              filed: 'sourceName',
              label: '文件类型',
              width: '100',
              isCustom: true,
              resizable: true
            },
            {
              filed: 'opt',
              label: '操作',
              width: '',
              isCustom: true,
              resizable: true
            }
          ],
          columns: [],
          total: 0
        }
      }
    },
    watch: {
      dialogFormVisible(val) {
        if (val) {
          console.log('this.filterList', this.fileList)
          this.tableData.columns = this.fileList
        }
      }
    },
    methods: {
      ok() {
        this.$emit('ok')
      },
      cancer() {
        this.$emit('ok')
      },
      isView(file) {
        console.log('file', file)
          const fileArr = ['PDF', 'JPEG', 'JPG', 'PNG']
          return fileArr.indexOf(file) > -1
      },
      viewFile(file) {
          window.open(`/backEnd/api/${config.supplyCode}/File/previewFmsFile?mblId=${this.mblId}&fileId=${file.id}&fileName=${file.fileName}`, '_blank')
      }
    }
  }
  </script>
  <style scoped>
  .opt {
    display: flex;
    justify-content: space-around;
    &:deep(.el-button) {
        padding: 0;
    }
  }
  .tips {
      width: 100%;
      text-align: right;
      height: 16px;
      font-size: 12px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #CDCDCD;
      line-height: 16px;
  }
  </style>

