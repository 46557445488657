var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [{ name: "drag", rawName: "v-drag" }],
      attrs: {
        title: _vm.editData.id ? "修改" : "新增",
        visible: _vm.dialogVisible,
        width: "520px",
        "append-to-body": true,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "before-close": _vm.cancel,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "form",
              attrs: { model: _vm.form, rules: _vm.rules },
            },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "shipper", label: "模板名称" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "100%" },
                    model: {
                      value: _vm.form.templateName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "templateName", $$v)
                      },
                      expression: "form.templateName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "shipper", label: "代理名称" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "100%" },
                    model: {
                      value: _vm.form.supplierName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "supplierName", $$v)
                      },
                      expression: "form.supplierName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "data" },
            [
              _c(
                "el-table",
                {
                  attrs: {
                    data: _vm.tableData,
                    height: 240,
                    size: "medium",
                    "header-cell-style": _vm.headerCellStyle,
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "emails",
                      label: "邮箱",
                      align: "center",
                      width: "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("el-input", {
                              staticStyle: { width: "100%" },
                              attrs: { placeholder: "请输入" },
                              model: {
                                value: scope.row.email,
                                callback: function ($$v) {
                                  _vm.$set(scope.row, "email", $$v)
                                },
                                expression: "scope.row.email",
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c(
                    "el-table-column",
                    {
                      attrs: { prop: "opt", align: "center", width: "120" },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              _c("i", {
                                staticClass: "el-icon-delete",
                                staticStyle: {
                                  color: "#2395f3",
                                  cursor: "pointer",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteRow(scope.$index)
                                  },
                                },
                              }),
                            ]
                          },
                        },
                      ]),
                    },
                    [
                      _c("template", { slot: "header" }, [
                        _c(
                          "div",
                          {
                            staticClass: "fp-cell",
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.add()
                              },
                            },
                          },
                          [
                            _c("svg-icon", {
                              attrs: { className: "icon1", iconClass: "add" },
                            }),
                            _vm._v(" 选择 "),
                          ],
                          1
                        ),
                      ]),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        { staticClass: "footers", attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "div",
            { staticClass: "cancel flex_c_c", on: { click: _vm.cancel } },
            [_vm._v("取 消")]
          ),
          _c(
            "div",
            {
              staticClass: "ok flex_c_c",
              on: {
                click: function ($event) {
                  return _vm.ok("form")
                },
              },
            },
            [_vm._v("确 定")]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }