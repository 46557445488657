<template>
  <Drawer
    :drawer="drawer"
    title="跟进记录"
    :withHeader="false"
    :size="1130"
    @close="close"
  >
    <div class="view">
      <div class="title">
        <div class="title_left">
          <div class="title-main">
            <img src="@/assets/images/bill/record-1.png" />FMS跟进记录
          </div>
          <div class="copy-no">
            <span>提单号：{{ rowData.blNo }}</span>
            <img
              src="@/assets/images/bill/copy.png"
              title="复制"
              @click="copy(rowData.blNo)"
            />
          </div>
        </div>
        <div class="title_right">
          <i class="el-icon-close icon" @click="close" />
        </div>
      </div>
      <div class="container">
        <div class="opt">
          <div class="btn" @click="dialogVisible = true">
            <img src="@/assets/images/bill/add.png" />
            新增
          </div>
        </div>
        <BaseTable
          class="table"
          height="460"
          :tableData="tableData"
          :isPager="false"
        >
          <template slot="createdOn" slot-scope="row">
            {{ row.scope.createdOn | formatterDate }}
          </template>
          <template slot="orderQty" slot-scope="row">
            {{ row.scope.orderQty }}
            <span class="view-data" @click="viewOrder(row.scope)">查看</span>
          </template>
          <template slot="fileQty" slot-scope="row">
            {{ row.scope.fileQty }}
            <span class="view-data" @click="viewFile(row.scope)">查看</span>
          </template>
        </BaseTable>
      </div>
      <AddFmsFollowUpDialog
        :dialogVisible="dialogVisible"
        :rowData="rowData"
        @ok="ok"
        @cancel="dialogVisible = false"
      ></AddFmsFollowUpDialog>
      <FmsFollowUpFileDialog
        :dialogFormVisible="dialogVisible2"
        :mblId="rowData.id"
        :fileList="fileList"
        @ok="dialogVisible2 = false"
      ></FmsFollowUpFileDialog>
      <FmsFollowUpOrderDialog
        :dialogFormVisible="dialogVisible3"
        :orderList="orderList"
        @ok="dialogVisible3 = false"
      ></FmsFollowUpOrderDialog>
    </div>
  </Drawer>
</template>

<script>
const Drawer = () => import('@/components/drawer/Drawer')
import AddFmsFollowUpDialog from './AddFmsFollowUpDialog.vue'
import FmsFollowUpFileDialog from './FmsFollowUpFileDialog.vue'
import FmsFollowUpOrderDialog from './FmsFollowUpOrderDialog.vue'
import { fmsMBLFollowUpList } from '@/api/bill'
const BaseTable = () => import('@/components/table/BaseTable')
export default {
  components: { Drawer, AddFmsFollowUpDialog, BaseTable, FmsFollowUpFileDialog, FmsFollowUpOrderDialog },
  filters: {
    formatterDate: function(date) {
      if (date) {
        return date.slice(0, 16)
      } else {
        return ''
      }
    }
  },
  props: {
    drawer: {
      type: Boolean,
      default: false
    },
    rowData: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      active: 0,
      typeItems: [],
      mainItem: [],
      terminologyId: '',
      description: '',
      terminologyType: '',
      dialogVisible: false,
      dialogVisible2: false,
      dialogVisible3: false,
      tableData: {
        fileds: [
          {
            filed: 'sendWayName',
            label: '派送方式',
            width: ''
          },
          {
            filed: 'typeName',
            label: '类型',
            width: ''
          },
          {
            filed: 'statusName',
            label: '状态'
          },
          {
            filed: 'remark',
            label: '描述',
            width: ''
          },
          {
            filed: 'createdName',
            label: '创建人'
          },
          {
            filed: 'createdOn',
            label: '创建时间',
            width: '140',
            isCustom: true
          },
          {
            filed: 'orderQty',
            label: '订单数量',
            width: '140',
            isCustom: true
          },
          {
            filed: 'fileQty',
            label: '文件数量',
            width: '140',
            isCustom: true
          }
        ],
        columns: [],
        total: 0,
        showIndex: true
      },
      fileList: [],
      orderList: []
    }
  },
  computed: {},
  watch: {
    drawer(val) {
      if (val) {
        this.typeItems = []
        this.mainItem = []
        // this.active = this.rowData.status
        this.getList()
      }
    }
  },
  created() {
    // this.getList()
  },
  mounted() {},
  methods: {
    async getList() {
      const data = {
        mblId: this.rowData.id || '442456957047493'
      }
      try {
        const res = await fmsMBLFollowUpList(data)
        if (res.success) {
          this.tableData.columns = res.data
        } else {
          this.$message.error(res.errorMessage)
          this.$emit('close')
        }
      } catch (error) {
        console.error(error)
      }
    },
    close() {
      this.$emit('close')
    },
    ok() {
      this.getList()
      this.dialogVisible = false
    },
    viewFile(row) {
      this.dialogVisible2 = true
      this.fileList = row.fileList
    },
    viewOrder(row) {
      this.dialogVisible3 = true
      this.orderList = row.orderList
    }
  }
}
</script>

<style lang="less" scoped>
.view {
  position: relative;
  width: 100%;
  height: 100%;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 60px;
    font-size: 16px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    border-bottom: 1px solid #f2f7ff;
    padding: 0 20px;
    box-sizing: border-box;
    .title_left {
      display: flex;
      align-items: center;
      .title-main {
        img {
          width: 24px;
          height: 24px;
          margin-right: 4px;
        }
      }
      .copy-no {
        margin-left: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: auto;
        height: 32px;
        background: #f7f7f7;
        border-radius: 4px 4px 4px 4px;
        padding: 6px 10px;
        box-sizing: border-box;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #999999;
        img {
          margin-left: 8px;
          cursor: pointer;
        }
      }
    }
    .title_right {
      .icon {
        font-size: 20px;
        cursor: pointer;
      }
    }
  }
  .container {
    width: 100%;
    height: calc(100% - 64px);
    .opt {
      margin: 20px 20px 10px;
      width: calc(100% - 40px);
      display: flex;
      flex-direction: row-reverse;
    }
    .btn {
      width: 80px;
      height: 30px;
      background: #42b983;
      border-radius: 2px 2px 2px 2px;
      opacity: 1;
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
      line-height: 30px;
      text-align: center;
      cursor: pointer;
      img {
        margin-top: 5px;
      }
    }
    .table {
      width: calc(100% - 40px);
      margin: 10px auto;
    }
    .view-data {
      background: #2395F3;
      border-radius: 9px 9px 9px 9px;
      padding: 1px 8px;
      font-size: 12px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #FFFFFF;
      cursor: pointer;
    }
  }
}
:deep(.el-drawer__body) {
  height: 100%;
}
</style>
