<template>
  <Drawer :drawer="drawer" customTitle :size="700" @close="close">
    <div slot="title" class="title">
      <span>对外系统提单确认-GGT</span>
      <div class="billNo">提单号：{{ billNo }}</div>
    </div>
    <div class="view">
      <div class="data">
        <el-form ref="form" class="form" :model="form" :rules="rules">
          <el-form-item prop="shipperSCINId" label="Shipper">
            <el-select
              v-model="form.shipperSCINId"
              style="width: 100%"
              filterable
              placeholder="请选择Shipper"
            >
              <el-option
                v-for="item in shipperOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="importerSCINId" label="Importer">
            <el-select
              v-model="form.importerSCINId"
              style="width: 100%"
              filterable
              placeholder="请选择Importer"
            >
              <el-option
                v-for="item in importerOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="consigneeSCINId" label="Consignee">
            <el-select
              v-model="form.consigneeSCINId"
              style="width: 100%"
              filterable
              placeholder="请选择Consignee"
            >
              <el-option
                v-for="item in consigneeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="notifyPartySCINId" label="Notify Party">
            <el-select
              v-model="form.notifyPartySCINId"
              style="width: 100%"
              filterable
              placeholder="请选择客户"
            >
              <el-option
                v-for="item in notifyPartyOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="manufactureSCINId" label="manufacturer">
            <el-select
              v-model="form.manufactureSCINId"
              style="width: 100%"
              filterable
              placeholder="请选择制造商"
            >
              <el-option
                v-for="item in manufactureOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-row>
            <div class="add" @click="dialogVisible1 = true">添加GGT抬头信息</div>
          </el-row>
          <el-form-item prop="doSupplierTemplateId" label="接收DO代理模板">
            <el-select
              v-model="form.doSupplierTemplateId"
              style="width: 100%"
              filterable
              placeholder="请选择接收DO代理模板"
              @change="doChange"
            >
              <el-option
                v-for="item in doOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <div class="tpl-opt" @click="dialogVisible = true">模板管理</div>
          </el-form-item>
          <el-form-item prop="shipper" label="接收DO公司名称">
            <el-input v-model="doObj.supplierName" style="width: 100%" disabled />
          </el-form-item>
          <el-form-item prop="shipper" label="接收DO公司邮箱">
            <el-input v-model="doObj.emails" style="width: 100%" disabled />
          </el-form-item>
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="提单文件">
                <div class="file">
                  <div class="file-name">
                    <img src="@/assets/images/bill/image.png" />
                    <div class="file-des">
                      <p v-tooltip="184" class="name">{{ fileList[0]?.fileName }}</p>
                    </div>
                  </div>
                  <div class="opt">
                    <span class="review" @click="check(fileList[0])">
                      <img src="@/assets/images/bill/review.png" />
                      预览
                    </span>
                  </div>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="提单发票">
                <div class="file">
                  <div class="file-name">
                    <img src="@/assets/images/bill/pdf.png" />
                    <div class="file-des">
                      <p v-tooltip="184" class="name">{{ fileList[1]?.fileName }}</p>
                    </div>
                  </div>
                  <div class="opt">
                    <span class="no-review"> 不可预览 </span>
                  </div>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="footers">
        <div class="cancel flex_c_c" @click="close">取 消</div>
        <div class="ok flex_c_c" @click="ok('form')">确 定</div>
      </div>
      <DoTplDialog
        :dialogVisible="dialogVisible"
        @cancel="dialogVisible = false"
      >
      </DoTplDialog>
      <SicnAddDialog
        :dialogVisible="dialogVisible1"
        :mblId="mblId"
        @cancel="dialogVisible1 = false"
        @ok="ok1">
      </SicnAddDialog>
    </div>
  </Drawer>
</template>

<script>
const Drawer = () => import('@/components/drawer/Drawer')
import DoTplDialog from './DoTplDialog.vue'
import SicnAddDialog from './SicnAddDialog.vue'
import { GGTGetSCINList, PushMBLToGGT } from '@/api/bill'
import { getMBLFile } from '@/api/system'
import config from '@/utils/config'
import { mapGetters } from 'vuex'
export default {
  components: { Drawer, DoTplDialog, SicnAddDialog },
  props: {
    drawer: {
      type: Boolean,
      default: false
    },
    billNo: {
      type: String,
      default: ''
    },
    mblId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      form: {
        shipperSCINId: '',
        consigneeSCINId: '',
        importerSCINId: '',
        notifyPartySCINId: '',
        manufactureSCINId: '',
        doSupplierTemplateId: ''
      },
      rules: {
        shipperSCINId: [
          { required: true, message: '请选择Shipper', trigger: 'change' }
        ],
        consigneeSCINId: [
          { required: true, message: '请选择Consignee', trigger: 'change' }
        ],
        importerSCINId: [
          { required: true, message: '请选择Importer', trigger: 'change' }
        ],
        notifyPartySCINId: [
          { required: true, message: '请选择Notify Party', trigger: 'change' }
        ],
        manufactureSCINId: [
          { required: true, message: '请选择manufacture', trigger: 'change' }
        ],
        doSupplierTemplateId: [
          { required: true, message: '请选择接收DO代理模板', trigger: 'change' }
        ]
      },
      dialogVisible: false,
      dialogVisible1: false,
      shipperOptions: [],
      consigneeOptions: [],
      importerOptions: [],
      notifyPartyOptions: [],
      manufactureOptions: [],
      doObj: {},
      fileList: []
    }
  },
  computed: {
    ...mapGetters(['doTplList']),
    doOptions() {
      return this.doTplList.map(a => {
        return {
          value: a.id,
          label: a.templateName
        }
      })
    }
  },
  watch: {
    drawer(val) {
      if (val) {
        this.getScinList()
        // this.getTpl()
        this.getFileList()
      }
    }
  },
  mounted() {
    if (this.doTplList.length === 0) {
      this.$store.dispatch('getDoTplList')
    }
  },
  methods: {
    async getScinList() {
      try {
        const res = await GGTGetSCINList({
          mblId: this.mblId,
          SCINType: 4
        })
        if (res.success) {
          this.shipperOptions = res.data.filter(a => a.type === 'shipper').map(a => {
            return {
              value: a.id,
              label: a.name
            }
          })
          this.consigneeOptions = res.data.filter(a => a.type === 'consignee').map(a => {
            return {
              value: a.id,
              label: a.name
            }
          })
          this.importerOptions = res.data.filter(a => a.type === 'importer').map(a => {
            return {
              value: a.id,
              label: a.name
            }
          })
          this.notifyPartyOptions = res.data.filter(a => a.type === 'notifyParty').map(a => {
            return {
              value: a.id,
              label: a.name
            }
          })
          this.manufactureOptions = res.data.filter(a => a.type === 'manufacture').map(a => {
            return {
              value: a.id,
              label: a.name
            }
          })
        } else {
          this.$message(res.errorMessage)
          this.$emit('close')
        }
      } catch (error) {
        console.log(error)
      }
    },
    async getFileList() {
      try {
        const res = await getMBLFile({
          id: this.mblId
        })
        if (res.success) {
          this.fileList = res.data.filter(a => a.type === 0 || a.type === 5)
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.log(error)
      }
    },
    doChange(id) {
      const item = this.doTplList.find(a => a.id === id)
      this.doObj = {
        templateName: item.templateName,
        supplierName: item.supplierName,
        emails: item.emails.join(',')
      }
    },
    check(item) {
      const suffix = item.fileName.slice(
        item.fileName.lastIndexOf('.') + 1,
        item.fileName.length
      )
      const { fileGuid } = item
      if (suffix === 'jpg' || suffix === 'jpeg' || suffix === 'png') {
        window.open(`/backEnd/api/${config.supplyCode}/File/preview?guid=${fileGuid}`)
      } else if (suffix === 'pdf') {
        window.open(`/backEnd/api/${config.supplyCode}/File/preview?guid=${fileGuid}`)
      } else {
        this.$message('暂时只支持图片和PDF查看')
      }
    },
    close() {
      this.$refs['form'].resetFields()
      this.$emit('close')
    },
    ok(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const params = {
            ...this.form,
            mblId: this.mblId
          }
          PushMBLToGGT(params).then((res) => {
            if (res.success) {
              this.$message.success('推送成功')
              this.$emit('close')
            } else {
              this.$message.error(res.errorMessage)
            }
          })
        }
      })
    },
    ok1() {
      this.dialogVisible1 = false
      this.getScinList()
    }
  }
}
</script>

<style lang="less" scoped>
.title {
  display: flex;
  span {
    line-height: 60px;
  }
  .billNo {
    padding: 0 10px;
    height: 32px;
    background: #f7f7f7;
    border-radius: 4px;
    margin: 14px 0 14px 20px;
    font-size: 14px;
    font-weight: 400;
    color: #2395f3;
    line-height: 32px;
  }
}
.view {
  width: 100%;
  height: 100%;
  font-weight: 400;
  box-sizing: border-box;
  position: relative;
  .data {
    width: 100%;
    max-height: calc(100% - 80px);
    margin: 0 auto;
    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: hidden;
    .form {
      width: calc(100% - 40px);
      margin: 0 auto;
      margin-top: 10px;
      &:deep(.el-form-item__label) {
        line-height: 30px;
        font-size: 14px;
      }
      &:deep(.el-form-item) {
        margin-bottom: 10px;
        position: relative;
      }
    }
    .add {
      width: 200px;
      height: 40px;
      background: #2395f3;
      border-radius: 4px 4px 4px 4px;
      opacity: 1;
      margin: 10px auto;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      line-height: 40px;
      text-align: center;
      cursor: pointer;
    }
    .tpl-opt {
      position: absolute;
      top: 0;
      right: 0;
      height: 30px;
      line-height: 30px;
      font-size: 14px;
      font-weight: 400;
      color: #2395f3;
      cursor: pointer;
    }
    .file {
      width: 290px;
      height: 40px;
      padding: 12px 20px 12px 10px;
      background: #ffffff;
      box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.1);
      border-radius: 6px 6px 6px 6px;
      opacity: 1;
      display: flex;
      position: relative;
      z-index: 1;
    }
    .file-name {
      flex: 1;
      display: flex;
    }
    .file-des {
      margin-left: 10px;
      .name {
        width: 184px;
        height: 40px;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: 40px;
      }
      // .size {
      //   height: 16px;
      //   font-size: 12px;
      //   font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      //   font-weight: 400;
      //   color: #999999;
      //   line-height: 16px;
      //   margin-top: 4px;
      // }
    }
    .opt {
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      line-height: 40px;
      .review {
        color: #2395f3;
        cursor: pointer;
      }
      .no-review {
        color: #cdcdcd;
        cursor: not-allowed;
      }
      img {
        vertical-align: sub;
      }
    }
  }
  .footers {
    width: 100%;
    height: 80px;
    background: #ffffff;
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.16);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    .cancel {
      width: 120px;
      height: 40px;
      background: #eeeeee;
      border-radius: 4px 4px 4px 4px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      margin-right: 16px;
      cursor: pointer;
    }
    .ok {
      width: 120px;
      height: 40px;
      background: #2395f3;
      border-radius: 4px 4px 4px 4px;
      font-size: 14px;
      cursor: pointer;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
    }
  }
}
</style>
