<template>
  <div v-loading="loading" class="table">
    <div class="header">
      <div class="cell" style="width: 30%">客户单号</div>
      <div class="cell">主单号</div>
      <div class="cell">件数</div>
      <div class="cell">上传时间</div>
      <div class="cell" style="width: 10%">操作</div>
      <div class="scoll"></div>
    </div>
    <div class="tbody">
      <div v-for="(item, $index) in dataList" :key="$index" class="row">
        <div class="top">
          <div class="cell">
            <el-checkbox
              v-model="item.isCheck"
              class="checkBox"
              :indeterminate="item.isIndeterminate"
              @change="handleCheckAllChange($index)"
            ></el-checkbox>
            <p>{{ item.referenceNumber }}</p>
          </div>
          <div class="cell">
            <p>{{ item.mstTrackingNumber }}</p>
          </div>
          <div class="cell">
            <p>{{ item.children.length || 1 }}</p>
          </div>
          <div class="cell">
            <p>{{ item.uploadTime }}</p>
          </div>
          <div class="cell">
            <el-button
              v-if="item.children.length > 1"
              type="text"
              @click="open($index, item.isCollapse)"
            >
              {{ item.isCollapse ? "收起" : "展开" }}
            </el-button>
          </div>
        </div>
        <div v-if="item.isCollapse" class="content">
          <div class="title">选择子单号</div>
          <div class="order-items" :style="{'width': size === 'small' ? '100%' : 'calc(100% - 200px)'}">
            <div
              v-for="order in item.children"
              :key="order.trackingNumber"
              class="order"
            >
              <el-checkbox
                v-model="order.isCheck"
                @change="handleCheckOrderChange($index)"
              ></el-checkbox>
              <span
                class="lable"
                :class="[{ checked: order.isCheck, uncheck: !order.isCheck }]"
              >
                {{ order.trackingNumber }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
let sourceData
import { getUnboundMblOrders } from '@/api/fee'
export default {
    props: {
        size: {
            type: String,
            default: 'normal' // normal small
        },
        rowData: {
          type: Object,
          default: () => {
            return {
              blNo: 'MATS123124'
            }
          }
        }
    },
  data() {
    return {
      dataList: [],
      loading: false
    }
  },
  async created() {
    await this.getUnbindList()
    this.$emit('input', this.loading)
  },
  methods: {
    async getUnbindList() {
      this.loading = true
      try {
        const res = await getUnboundMblOrders([], { companyId: this.rowData.companyId })
        this.loading = false
        if (res.success) {
          sourceData = res.data
          this.filterOrder()
        }
        // console.log('res', arr)
      } catch (error) {
        this.loading = false
        console.log(error)
      }
    },
    filterOrder(text) {
        const arr = []
        const data = text && text.length > 0 ? sourceData.filter(item => {
            return text.indexOf(item.mstTrackingNumber) > -1 ||
            text.indexOf(item.trackingNumber) > -1 ||
            text.indexOf(item.referenceNumber.split('-')[0]) > -1 ||
            text.indexOf(item.referenceNumber) > -1
        }) : sourceData
        data.forEach((item) => {
          const zIndex = arr.findIndex(
            (a) => a.mstTrackingNumber === item.mstTrackingNumber
          )
          if (zIndex > -1) {
            arr[zIndex].children.push({
              ...item,
              isCheck: false // 是否勾选
            })
          } else {
            arr.push({
              ...item,
              isIndeterminate: false, // 是否不确定态
              isCollapse: false, // 是否展开
              isCheck: false, // 是否勾选
              children: [item] // 子单号
            })
          }
        })
        this.dataList = arr
    },
    filterOrderBySelected(selectedArr) {
      const arr = []
        const data = selectedArr.length > 0 ? sourceData.filter(item => {
            return !selectedArr.find(a => {
              return a.mstTrackingNumber === item.mstTrackingNumber &&
                      a.trackingNumber === item.trackingNumber &&
                      a.referenceNumber === item.referenceNumber
            })
        }) : sourceData
        data.forEach((item) => {
          const zIndex = arr.findIndex(
            (a) => a.mstTrackingNumber === item.mstTrackingNumber
          )
          if (zIndex > -1) {
            arr[zIndex].children.push({
              ...item,
              isCheck: false // 是否勾选
            })
          } else {
            arr.push({
              ...item,
              isIndeterminate: false, // 是否不确定态
              isCollapse: false, // 是否展开
              isCheck: false, // 是否勾选
              children: [item] // 子单号
            })
          }
        })
        this.dataList = arr
    },
    open(index, flag) {
      this.$set(this.dataList, index, {
        ...this.dataList[index],
        isCollapse: !flag
      })
    },
    handleCheckAllChange(index) {
      const children = this.dataList[index].children.map((item) => {
        return {
          ...item,
          isCheck: this.dataList[index].isCheck
        }
      })
      const mstOrder = {
        ...this.dataList[index],
        isIndeterminate: false,
        children
      }
      this.$set(this.dataList, index, mstOrder)
      this.selectItems()
    },
    handleCheckOrderChange(index) {
      const isCheckLength = this.dataList[index].children.filter(
        (a) => a.isCheck === true
      ).length
      const mstOrder = {
        ...this.dataList[index],
        isIndeterminate: !(
          isCheckLength === this.dataList[index].children.length ||
          isCheckLength === 0
        ),
        isCheck: isCheckLength === this.dataList[index].children.length
      }
      this.$set(this.dataList, index, mstOrder)
      this.selectItems()
    },
    checkAll() {
      const list = this.dataList.map((item) => {
        const children = item.children.map((a) => {
          return {
            ...a,
            isCheck: true
          }
        })
        return {
          ...item,
          isCheck: true,
          children
        }
      })
      this.dataList = list
      this.selectItems()
    },
    unCheckAll() {
      const list = this.dataList.map((item) => {
        const children = item.children.map((a) => {
          return {
            ...a,
            isCheck: false
          }
        })
        return {
          ...item,
          isCheck: false,
          children
        }
      })
      this.dataList = list
      this.selectItems()
    },
    selectItems() {
      const dataList = this.dataList
      const arr = []
      dataList.forEach((item) => {
        if (item.isCheck && !item.isIndeterminate) {
          arr.push(item)
        } else if (item.isIndeterminate) {
          const children = item.children.filter((a) => a.isCheck === true)
          if (children.length > 0) {
            arr.push({
              ...item,
              children
            })
          }
        }
      })
      this.$emit('selectItems', arr)
    }
  }
}
</script>
<style lang="less" scoped>
.table {
  width: 100%;
  height: 100%;
  .header {
    width: 100%;
    height: 48px;
    border-radius: 6px;
    background: #fff;
    display: flex;
    .cell {
      background: #f8fbff;
      width: 20%;
      height: 48px;
      line-height: 48px;
      text-align: center;
      &:first-child {
        width: 30%;
      }
      &:last-child {
        width: 10%;
      }
    }
    .scoll {
      width: 10px;
    }
  }
  .cell {
    width: 20%;
  }
  .tbody {
    width: 100%;
    height: 400px;
    overflow: auto;
    .row {
      width: calc(100% - 2px);
      min-height: 58px;
      background: #ffffff;
      box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.05);
      border-radius: 6px;
      margin-top: 16px;
      &:last-child {
        margin-bottom: 5px;
      }
      & .top {
        display: flex;
        min-height: 58px;
      }
    }
    .cell {
      height: 19px;
      font-size: 14px;
      font-weight: 400;
      color: #25396f;
      line-height: 58px;
      text-align: center;
      position: relative;
      &:first-child {
        width: 30%;
      }
      &:last-child {
        width: 10%;
      }
    }
    .checkBox {
      position: absolute;
      left: 20px;
    }
    .content {
      width: calc(100% - 32px);
      margin: 0 auto;
      height: auto;
    }
    .title {
      height: 48px;
      background: #fbfcff;
      border-radius: 6px;
      text-indent: 160px;
      font-size: 14px;
      font-weight: 400;
      color: #25396f;
      line-height: 48px;
    }
    .order-items {
      width: calc(100% - 200px);
      margin: 0 auto;
      padding-top: 24px;
      display: flex;
      flex-wrap: wrap;
    }
    .order {
      width: 16.666%;
      margin-bottom: 24px;
      font-size: 14px;
      font-weight: 400;
      color: #25396f;
      line-height: 19px;
      min-width: 200px;
      .lable {
        margin-left: 16px;
      }
    }
    .checked {
      color: #25396f;
    }
    .uncheck {
      color: #7c8db5;
    }
  }
}
</style>
