<template>
  <el-dialog
    v-drag
    title="DO代理管理"
    :visible.sync="dialogVisible"
    width="1200px"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
  >
    <div class="content">
      <div class="opt">
        <div class="add" @click="dialogVisible2 = true">
          <svg-icon className="icon" iconClass="add"></svg-icon>
          新增
        </div>
        <el-input placeholder="请输入模板名称" style="width: 320px" />
      </div>
      <div class="data">
        <BaseTable
          :height="440"
          :isPager="false"
          :border="true"
          :tableData="tableData"
          :loading="loading"
        >
          <template slot="opt" slot-scope="row">
            <div>
              <i
                class="el-icon-edit-outline icon1"
                style="color: #2395f3; cursor: pointer"
                @click="editRow(row.scope)"
              />
              <i
                class="el-icon-delete icon1"
                style="color: #2395f3; cursor: pointer"
                @click="deleteRow(row.scope)"
              />
            </div>
          </template>
        </BaseTable>
      </div>
    </div>
    <AddDoTplDialog
      :dialogVisible="dialogVisible2"
      :editData="editData"
      @ok="dialogVisible2 = false"
      @cancel="dialogVisible2 = false"
    ></AddDoTplDialog>
  </el-dialog>
</template>

<script>
const BaseTable = () => import('@/components/table/BaseTable.vue')
import AddDoTplDialog from './AddDoTplDialog.vue'
import { mapGetters } from 'vuex'
import { DeleteDoTemplate } from '@/api/bill'
export default {
  components: { BaseTable, AddDoTplDialog },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    typeId: {
      type: Number,
      require: true
    }
  },
  data() {
    return {
      dialogVisible2: false,
      loading: false,
      editData: {}
    }
  },
  computed: {
    ...mapGetters(['doTplList']),
    tableData() {
      return {
        fileds: [
          {
            filed: 'templateName',
            label: '模板名称',
            width: '240',
            resizable: true
          },
          {
            filed: 'supplierName',
            label: '代理名称',
            width: '240',
            resizable: true
          },
          {
            filed: 'emails',
            label: '邮箱',
            width: '',
            formatter: row => {
              return row.emails.join(';')
            },
            resizable: true
          },
          {
            filed: 'opt',
            label: '操作',
            width: '110',
            isCustom: true,
            resizable: true
          }
        ],
        columns: this.doTplList,
        total: 0
      }
    }
  },
  mounted() {
    if (this.doTplList.length === 0) {
      this.$store.dispatch('getDoTplList')
    }
  },
  methods: {
    cancel() {
      this.$emit('cancel')
    },
    deleteRow(row) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        iconClass: 'warning',
        showClose: false,
        closeOnPressEscape: false,
        closeOnClickModal: false,
        center: true
      })
        .then(() => {
          DeleteDoTemplate({
            doTemplateId: row.id
          }).then((res) => {
            if (res.success) {
              this.$message.success('删除成功')
              this.$store.dispatch('getDoTplList')
            } else {
              this.$message.error(res.errorMessage)
            }
          })
        })
        .catch(() => {})
    },
    editRow(row) {
      this.editData = row
      this.dialogVisible2 = true
    },
    cancel1() {
      this.editData = {}
      this.dialogVisible2 = false
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  width: 100%;
  height: auto;
  margin: 0 auto;
  .opt {
    width: 100%;
    height: 40px;
    display: flex;
    flex-flow: row-reverse;
    .add {
      width: 100px;
      height: 40px;
      background: #35ba83;
      border-radius: 4px 4px 4px 4px;
      opacity: 1;
      margin-left: 10px;
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
      line-height: 40px;
      text-align: center;
      cursor: pointer;
      .icon {
        font-size: 20px;
        vertical-align: -5px;
      }
    }
  }
  .data {
    width: 100%;
    margin: 20px auto 0;
    height: 440px;
    border-radius: 4px;
    overflow: hidden;
  }
  .icon1 {
    cursor: pointer;
    font-size: 20px;
    & + .icon1 {
      margin-left: 10px;
    }
  }
}
/deep/ .el-dialog__body {
  padding: 0px 20px 40px;
}
/deep/ .el-dialog__header {
  border: 0;
}
</style>
