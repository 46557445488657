var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [{ name: "drag", rawName: "v-drag" }],
      attrs: {
        title: "文件列表",
        visible: _vm.dialogFormVisible,
        width: "872px",
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "before-close": _vm.cancer,
        center: "",
        "append-to-body": true,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
      },
    },
    [
      _c("BaseTable", {
        attrs: {
          height: _vm.height,
          tableData: _vm.tableData,
          isPager: false,
          border: true,
          loading: false,
        },
        scopedSlots: _vm._u([
          {
            key: "createdOn",
            fn: function (row) {
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      row.scope.createdOn
                        ? row.scope.createdOn.slice(0, 16).replace("T", " ")
                        : ""
                    ) +
                    " "
                ),
              ]
            },
          },
          {
            key: "sourceName",
            fn: function (row) {
              return [
                _vm._v(
                  " " +
                    _vm._s(row.scope.fileName.split(".")[1].toUpperCase()) +
                    " "
                ),
              ]
            },
          },
          {
            key: "opt",
            fn: function (row) {
              return [
                _c(
                  "div",
                  { staticClass: "opt" },
                  [
                    _vm.isView(row.scope.fileName.split(".")[1].toUpperCase())
                      ? _c(
                          "el-button",
                          {
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.viewFile(row.scope)
                              },
                            },
                          },
                          [
                            _c("img", {
                              staticStyle: { "vertical-align": "-4px" },
                              attrs: {
                                src: require("@/assets/images/bill/view.png"),
                              },
                            }),
                            _vm._v(" 查看 "),
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "el-link",
                      {
                        staticStyle: { "margin-top": "-2px" },
                        attrs: {
                          underline: false,
                          type: "primary",
                          href: `/backEnd/api/${_vm.config.supplyCode}/File/downLoadFmsFile?mblId=${_vm.mblId}&fileId=${row.scope.id}&fileName=${row.scope.fileName}`,
                          target: "download",
                        },
                      },
                      [
                        _c("img", {
                          staticStyle: { "vertical-align": "-4px" },
                          attrs: {
                            src: require("@/assets/images/bill/download.png"),
                          },
                        }),
                        _vm._v(" 下载 "),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _c("p", { staticClass: "tips" }, [_vm._v("*暂时只支持图片和PDF查看")]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }