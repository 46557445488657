<template>
  <el-dialog
    v-drag
    :title="type"
    :visible.sync="dialogVisible"
    width="520px"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
  >
    <div class="content">
      <el-form ref="form" class="data" :model="form" label-position="top">
        <el-form-item
          :label="formatLabel(type)"
          prop="date"
          :rules="[{ required: true, message: formatRules(type) }]"
        >
          <el-date-picker
            v-model="form.date"
            type="date"
            style="width: 100%"
            placeholder="请选择时间"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="footers">
      <div class="cancel flex_c_c" @click="cancel('form')">取 消</div>
      <div class="ok flex_c_c" @click="ok('form')">确 定</div>
    </span>
  </el-dialog>
</template>
<script>
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: '到港'
    }
  },
  data() {
    return {
      // pickerOptions: {
      //   disabledDate(time) {
      //     const nowDate = new Date(new Date().toLocaleDateString())
      //     return time.getTime() < nowDate
      //   }
      // },
      form: {
        date: ''
      }
    }
  },
  methods: {
    formatLabel(val) {
      const obj = {
        '到港': '到港时间',
        '卸船': '卸船时间',
        '到达': '实际到达时间(ATA)'
      }
      return obj[val]
    },
    formatRules(val) {
      const obj = {
        '到港': '到港时间不能为空',
        '卸船': '卸船时间不能为空',
        '到达': '到达时间不能为空'
      }
      return obj[val]
    },
    cancel() {
      this.$emit('close')
      this.$refs['form'].resetFields()
    },
    ok(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$emit('ok', this.form.date)
          this.$refs[formName].resetFields()
        } else {
          return false
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
/deep/ .el-dialog__body {
  padding: 20px 20px;
}
.footers {
  width: 100%;
  display: flex;
  justify-content: center;
  .cancel {
    width: 120px;
    height: 40px;
    background: #eeeeee;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    margin-right: 16px;
    cursor: pointer;
  }
  .ok {
    width: 120px;
    height: 40px;
    background: #2395f3;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    cursor: pointer;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
}
.content {
  padding: 0 40px;
  box-sizing: border-box;
}
</style>
