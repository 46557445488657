var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [{ name: "drag", rawName: "v-drag" }],
      attrs: {
        title: "到仓",
        visible: _vm.dialogVisible,
        width: "1000px",
        "append-to-body": true,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "before-close": _vm.cancel,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "top" },
        [
          _c("span", [_vm._v("首次到仓时间: ")]),
          _c("el-date-picker", {
            attrs: {
              type: "datetime",
              placeholder: "选择首次到仓时间",
              "value-format": "yyyy-MM-dd HH:mm:ss",
            },
            model: {
              value: _vm.picArrivedDate,
              callback: function ($$v) {
                _vm.picArrivedDate = $$v
              },
              expression: "picArrivedDate",
            },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "content" }, [
        _c("ul", { staticClass: "row" }, [
          _c("li", { staticClass: "row header" }, [
            _c("div", { staticClass: "cell1" }, [_vm._v("派送类型")]),
            _c("div", { staticClass: "cell2" }, [_vm._v("提单件数")]),
            _c("div", { staticClass: "cell3" }, [_vm._v("已到仓数")]),
            _c("div", { staticClass: "cell4" }, [_vm._v("本次到仓数")]),
            _c("div", { staticClass: "cell5" }, [_vm._v("到仓备注")]),
          ]),
          _c(
            "div",
            { staticClass: "data" },
            _vm._l(_vm.dataList, function (item, $index) {
              return _c("li", { key: $index, staticClass: "row bodyRow" }, [
                _c("div", { staticClass: "cell1" }, [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        item.code
                          ? `${item.typeName}-${item.code}`
                          : item.typeName
                      )
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "cell2" }, [
                  _c("span", [_vm._v(_vm._s(item.qty))]),
                ]),
                _c("div", { staticClass: "cell3" }, [
                  _c("span", [_vm._v(_vm._s(item.arrivalQty))]),
                ]),
                _c(
                  "div",
                  { staticClass: "cell4" },
                  [
                    _c("el-input", {
                      model: {
                        value: item.thisTimeArrivalQty,
                        callback: function ($$v) {
                          _vm.$set(item, "thisTimeArrivalQty", $$v)
                        },
                        expression: "item.thisTimeArrivalQty",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "cell5" },
                  [
                    _c("el-input", {
                      model: {
                        value: item.arrivalRemarks,
                        callback: function ($$v) {
                          _vm.$set(item, "arrivalRemarks", $$v)
                        },
                        expression: "item.arrivalRemarks",
                      },
                    }),
                  ],
                  1
                ),
              ])
            }),
            0
          ),
        ]),
      ]),
      _c(
        "span",
        { staticClass: "footers", attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "div",
            { staticClass: "cancel flex_c_c", on: { click: _vm.cancel } },
            [_vm._v("取 消")]
          ),
          _c("div", { staticClass: "ok flex_c_c", on: { click: _vm.ok } }, [
            _vm._v("确 定"),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }