var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [{ name: "drag", rawName: "v-drag" }],
      attrs: {
        visible: _vm.dialogVisible,
        width: "1190px",
        "show-close": true,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "before-close": _vm.cancel,
        title: "查看轨迹",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "header" }, [
          _c("div", { staticClass: "tab" }, [
            _vm.billType !== 1
              ? _c(
                  "span",
                  {
                    class: ["tabItem", _vm.active === 0 ? "active" : ""],
                    on: {
                      click: function ($event) {
                        return _vm.changeType(0)
                      },
                    },
                  },
                  [_vm._v("船司轨迹")]
                )
              : _vm._e(),
            _c(
              "span",
              {
                class: ["tabItem", _vm.active === 1 ? "active" : ""],
                on: {
                  click: function ($event) {
                    return _vm.changeType(1)
                  },
                },
              },
              [_vm._v("清关轨迹")]
            ),
          ]),
        ]),
        !_vm.active
          ? _c(
              "div",
              { staticClass: "data" },
              [
                _c("BaseTable", {
                  attrs: {
                    height: "460",
                    tableData: {
                      ..._vm.tableData,
                      fileds: _vm.fileds,
                    },
                    isPager: false,
                    border: true,
                  },
                }),
              ],
              1
            )
          : _c(
              "div",
              { staticClass: "panel" },
              [
                _c(
                  "el-row",
                  [
                    _c("el-col", { attrs: { span: 6 } }, [
                      _c("div", { staticClass: "item" }, [
                        _c("div", { staticClass: "label" }, [_vm._v("状态码")]),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: 160,
                                expression: "160",
                              },
                            ],
                          },
                          [_vm._v(_vm._s(_vm.clearance.lastDispositionCode))]
                        ),
                      ]),
                    ]),
                    _c("el-col", { attrs: { span: 6 } }, [
                      _c("div", { staticClass: "item" }, [
                        _c("div", { staticClass: "label" }, [
                          _vm._v("主提单号"),
                        ]),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: 160,
                                expression: "160",
                              },
                            ],
                          },
                          [_vm._v(_vm._s(_vm.clearance.masterBLNo))]
                        ),
                      ]),
                    ]),
                    _c("el-col", { attrs: { span: 6 } }, [
                      _c("div", { staticClass: "item" }, [
                        _c("div", { staticClass: "label" }, [
                          _vm._v("分提单号"),
                        ]),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: 160,
                                expression: "160",
                              },
                            ],
                          },
                          [_vm._v(_vm._s(_vm.clearance.houseNo))]
                        ),
                      ]),
                    ]),
                    _c("el-col", { attrs: { span: 6 } }, [
                      _c("div", { staticClass: "item" }, [
                        _c("div", { staticClass: "label" }, [_vm._v("件数")]),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: 160,
                                expression: "160",
                              },
                            ],
                          },
                          [_vm._v(_vm._s(_vm.clearance.manifestQuantity))]
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  { staticStyle: { "margin-top": "15px" } },
                  [
                    _c("el-col", { attrs: { span: 6 } }, [
                      _c("div", { staticClass: "item" }, [
                        _c("div", { staticClass: "label" }, [
                          _vm._v("轨迹时间"),
                        ]),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: 160,
                                expression: "160",
                              },
                            ],
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm._f("dateFilete")(
                                  _vm.clearance.lastEventDate
                                )
                              )
                            ),
                          ]
                        ),
                      ]),
                    ]),
                    _c("el-col", { attrs: { span: 6 } }, [
                      _c("div", { staticClass: "item" }, [
                        _c("div", { staticClass: "label" }, [
                          _vm._v("放行时间"),
                        ]),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: 160,
                                expression: "160",
                              },
                            ],
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm._f("dateFilete")(_vm.clearance.releasedDate)
                              )
                            ),
                          ]
                        ),
                      ]),
                    ]),
                    _c("el-col", { attrs: { span: 6 } }, [
                      _c("div", { staticClass: "item" }, [
                        _c("div", { staticClass: "label" }, [
                          _vm._v("更新时间"),
                        ]),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: 160,
                                expression: "160",
                              },
                            ],
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm._f("dateFilete")(_vm.clearance.lastRunTime)
                              )
                            ),
                          ]
                        ),
                      ]),
                    ]),
                    _c("el-col", { attrs: { span: 6 } }, [
                      _c("div", { staticClass: "item" }, [
                        _c("div", { staticClass: "label" }, [
                          _vm._v("扣件时间"),
                        ]),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: 160,
                                expression: "160",
                              },
                            ],
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm._f("dateFilete")(_vm.clearance.holdDate)
                              )
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  { staticStyle: { "margin-top": "15px" } },
                  [
                    _c("el-col", { attrs: { span: 6 } }, [
                      _c("div", { staticClass: "item" }, [
                        _c("div", { staticClass: "label" }, [
                          _vm._v("扣件解除日期"),
                        ]),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip",
                                value: 160,
                                expression: "160",
                              },
                            ],
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm._f("dateFilete")(
                                  _vm.clearance.holdRemoveDate
                                )
                              )
                            ),
                          ]
                        ),
                      ]),
                    ]),
                    _c("el-col", { attrs: { span: 18 } }, [
                      _c(
                        "div",
                        { staticClass: "item", staticStyle: { width: "auto" } },
                        [
                          _c("div", { staticClass: "label" }, [
                            _vm._v("轨迹描述"),
                          ]),
                          _c(
                            "span",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: 733,
                                  expression: "733",
                                },
                              ],
                            },
                            [_vm._v(_vm._s(_vm.clearance.statusDesc))]
                          ),
                        ]
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
      ]),
      !_vm.active
        ? _c(
            "span",
            {
              staticClass: "footers",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "div",
                { staticClass: "cancel flex_c_c", on: { click: _vm.cancel } },
                [_vm._v("取 消")]
              ),
              _c(
                "div",
                { staticClass: "ok flex_c_c", on: { click: _vm.cancel } },
                [_vm._v("确 定")]
              ),
            ]
          )
        : _c(
            "span",
            {
              staticClass: "footers",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  staticClass: "ok flex_c_c",
                  attrs: { icon: "el-icon-refresh" },
                  on: {
                    click: function ($event) {
                      return _vm.reload(1)
                    },
                  },
                },
                [_vm._v("刷 新")]
              ),
            ],
            1
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }