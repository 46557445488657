<template>
  <el-dialog
    v-drag
    :visible.sync="dialogVisible"
    width="748px"
    :show-close="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="beforeClose"
    title="导入订单"
    append-to-body
  >
    <div class="container">
      <div class="upload-area">
        <div>
          <el-upload
            class="upload-demo"
            :action="`/backEnd/api/${config.supplyCode}/MBL/orderBindingMBL?blNo=${rowData.blNo}${isExpress}`"
            :limit="1"
            :on-progress="handlePorgress"
            :on-success="handleSuccess"
            :on-error="handleError"
            :file-list="fileList"
            :show-file-list="false"
          >
            <el-button
              v-loading="loading"
              size="small"
              type="primary"
              class="btn"
              style="line-height: 1"
            >
              <img
                style="
                  padding: 0;
                  padding-right: 6px;
                  vertical-align: middle;
                  margin-top: -2px;
                "
                src="@/assets/images/bill/upload.png"
              />{{ tableData.columns.length > 0 ? "重新导入" : "导入订单" }}
            </el-button>
          </el-upload>
        </div>
      </div>
      <BaseTable
        style="margin-top: 16px"
        :height="240"
        :tableData="tableData"
        :isPager="false"
        :border="true"
      >
        <template slot="sendWay" slot-scope="row">
          {{ row.scope.sendWay | formatterSendway }}
        </template>
      </BaseTable>
    </div>
    <span slot="footer" class="footer">
      <div class="btn cancel" @click="cancel">取消</div>
      <div class="btn ok" @click="ok('form')">提交</div>
    </span>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
const BaseTable = () => import('@/components/table/BaseTable')
import config from '@/utils/config'
import { onLoadMBL } from '@/api/bill'
export default {
  components: { BaseTable },
  filters: {
    formatterSendway(name) {
      const item = config.sendWayOptions.find((a) => a.value === name)
      return item.label
    }
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    rowData: {
      type: Object,
      default: () => {
        return {
          blNo: 'MATS123124'
        }
      }
    },
    sandwayObj: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      config,
      btnText: '导入订单',
      fileList: [],
      loading: false,
      tableData: {
        fileds: [
          {
            filed: 'sendWayName',
            label: '处理方式',
            width: '',
            resizable: true
          },
          {
            filed: 'sendWay',
            label: '派送方式',
            width: '',
            isCustom: true,
            resizable: true
          },
          {
            filed: 'qty',
            label: '订单件数',
            width: '',
            resizable: true
          }
        ],
        columns: [],
        total: 0,
        spanMethod: ({ row, column, rowIndex, columnIndex }) => {
          console.log(row, column, rowIndex, columnIndex)
          if (columnIndex === 0) {
            const rowMuti = []
            const index = this.tableData.columns.findIndex(
              (a) => a.sendWayName === row.sendWayName
            )
            if (index === rowIndex) {
              // 如果第一次出现
              for (let i = rowIndex; i < this.tableData.columns.length; i++) {
                if (this.tableData.columns[i].sendWayName === row.sendWayName) {
                  rowMuti.push(this.tableData.columns[i])
                } else {
                  break
                }
              }
            }
            if (
              rowMuti.length > 0 ||
              rowIndex === this.tableData.columns.length - 1
            ) {
              return {
                rowspan: rowMuti.length || 1,
                colspan: 1
              }
            } else {
              return {
                rowspan: 0,
                colspan: 0
              }
            }
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters(['selectSupply']),
    isExpress() {
      if (
        this.sandwayObj.code?.toUpperCase() === 'UPS' ||
        this.sandwayObj.code?.toUpperCase() === 'FEDEX'
      ) {
        return `&express=${this.sandwayObj.code}`
      } else {
        return ''
      }
    }
  },
  methods: {
    cancel() {
      this.tableData.columns = []
      this.$emit('cancel')
    },
    async ok() {
      if (this.tableData.columns.length === 0) return
      try {
        const params = {
          mblId: this.rowData?.id,
          operation: 0,
          orderItems: []
        }
        this.tableData.columns.forEach((item) => {
          item.orders.forEach((a) => {
            params.orderItems.push({
              ...a,
              sendWay: item.sendWay,
              sendWayName: item.sendWayName
            })
          })
        })
        const res = await onLoadMBL(params)
        if (res.success) {
          this.$message.success('绑定成功')
          this.$emit('ok')
        } else {
          this.$message.error(res.errorMessage)
        }
        console.log(res)
      } catch (error) {
        console.log(error)
      }
    },
    beforeClose() {
      this.cancel()
    },
    handlePorgress() {
      this.loading = true
    },
    handleSuccess(res) {
      this.loading = false
      if (res.success) {
        let str = ''
        if (res.data.errorItems.length > 0) {
          res.data.errorItems.forEach((b) => {
            str += `${b}<br/>`
          })
          this.$message({
            type: 'error',
            dangerouslyUseHTMLString: true,
            message: str
          })
        }
        this.tableData.columns = res.data.items
        console.log(res)
      } else {
        this.$message.error(res.errorMessage)
      }
      this.fileList = []
    },
    handleError(error) {
      this.loading = false
      this.$message.error(error)
    }
  }
}
</script>

<style lang="less" scoped>
.footer {
  display: flex;
  justify-content: center;
}
.container {
  box-sizing: border-box;
  .upload-area {
    width: 700px;
    height: 160px;
    background: #f8fbff;
    border-radius: 6px 6px 6px 6px;
    opacity: 1;
    border: 1px dashed #dbe4f2;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.btn {
  line-height: 38px;
  text-align: center;
  cursor: pointer;
  width: 120px;
  height: 38px;
  border-radius: 4px 4px 4px 4px;
  font-size: 14px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
}
.cancel {
  border: 1px solid #dbe4f2;
  color: #7c8db5;
  background: #f2f7ff;
  margin-right: 16px;
}
.ok {
  border: 1px solid #2395f3;
  color: #ffffff;
  background: #2395f3;
}
</style>
