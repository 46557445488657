var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [{ name: "drag", rawName: "v-drag" }],
      attrs: {
        title: "查看订单",
        visible: _vm.dialogFormVisible,
        width: "690px",
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "before-close": _vm.cancer,
        center: "",
        "append-to-body": true,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
      },
    },
    [
      _c("BaseTable", {
        attrs: {
          height: _vm.height,
          tableData: _vm.tableData,
          isPager: false,
          border: true,
          loading: false,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }