<template>
  <el-dialog
    v-drag
    :visible.sync="dialogVisible"
    width="728px"
    :show-close="true"
    title="自提-卡派-订单明细"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="beforeClose"
    append-to-body
  >
    <div class="container">
      <div class="search">
        <el-input
          v-model="text"
          style="width: 200px"
          size="mini"
          placeholder="请输入单号搜索"
          suffix-icon="el-icon-search"
        >
        </el-input>
      </div>
      <BaseTable
        style="margin-top: 16px"
        :height="440"
        :tableData="{
          ...tableData,
          columns
        }"
        :isPager="false"
        :border="true"
        @handleSelectionChange="selectItems"
      >
      </BaseTable>
      <div class="num">
        <span
          class="sum"
        >已选择
          <span
            style="color: rgba(35, 149, 243, 1)"
          >{{ selectArr.length }}
          </span>
          项</span
        >
      </div>
    </div>
    <span slot="footer" class="footer">
      <div class="btn cancel" @click="cancel">取消</div>
      <div class="btn ok" @click="deleteRow">
        <i class="el-icon-delete" />
        删除</div>
    </span>
  </el-dialog>
</template>
<script>
import { onLoadMBL } from '@/api/bill'
const BaseTable = () => import('@/components/table/BaseTable')
export default {
    components: { BaseTable },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    orderData: {
      type: Array,
      default: function() {
        return []
      }
    },
    rowData: {
      type: Object,
      default: () => {
        return {
          blNo: 'MATS123124'
        }
      }
    },
    sendWay: {
        type: Number,
        default: 0
    }
  },
  data() {
    return {
      selectArr: [],
      text: '',
      tableData: {
        fileds: [
          {
            filed: 'masterTrackingNumber',
            label: '主单号',
            width: '',
            resizable: true
          },
          {
            filed: 'referenceNumber',
            label: '参考单号',
            width: '',
            resizable: true
          },
          {
            filed: 'trackingNumber',
            label: '子单号',
            width: '',
            resizable: true
          }
        ],
        columns: [],
        total: 0,
        mutiCheck: true
      }
    }
  },
  computed: {
    columns() {
        return this.text ? this.tableData.columns.filter(item => {
            return item.mstTrackingNumber === this.text ||
            item.trackingNumber === this.text ||
            item.referenceNumber.split('-')[0] === this.text ||
            item.referenceNumber === this.text
        }) : this.tableData.columns
    }
  },
  watch: {
    dialogVisible(val) {
      if (val) {
        this.tableData.columns = this.orderData
        this.selectArr = []
      }
    }
  },
  methods: {
    cancel() {
      this.$emit('cancel')
    },
    beforeClose() {
      this.cancel()
    },
    selectItems(selectArr) {
      this.selectArr = selectArr
    },
   async deleteRow() {
        try {
        const params = {
            mblId: this.rowData?.id,
            operation: 2,
            orderItems: []
        }
        this.selectArr.forEach(item => {
            params.orderItems.push({
                // ...item,
                orderId: item.id,
                trackingNumber: item.trackingNumber,
                sendWay: this.sendWay
            })
        })
        const res = await onLoadMBL(params)
        if (res.success) {
            this.$message.success('解绑成功')
            this.$emit('ok')
        } else {
            this.$message.error(res.errorMessage)
        }
        console.log(res)
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.footer {
  width: 100%;
  height: 80px;
  background: #ffffff;
  box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.16);
  border-radius: 0px 0px 6px 6px;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.container {
  padding: 0 0px;
  box-sizing: border-box;
  .search {
    display: flex;
    flex-direction: row-reverse;
  }
  .num {
    .sum {
      margin-top: 16px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #b8c3de;
      line-height: 24px;
    }
  }
}
.btn {
  line-height: 38px;
  text-align: center;
  cursor: pointer;
  width: 120px;
  height: 38px;
  border-radius: 4px 4px 4px 4px;
  font-size: 14px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
}
.cancel {
  border: 1px solid #dbe4f2;
  color: #7c8db5;
  background: #f2f7ff;
  margin-right: 16px;
}
.ok {
  border: 1px solid #2395f3;
  color: #ffffff;
  background: #2395f3;
}
:deep(.el-dialog__footer) {
  padding: 0;
}
</style>
