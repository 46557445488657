var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [{ name: "drag", rawName: "v-drag" }],
      attrs: {
        title: "HOLD",
        visible: _vm.dialogVisible,
        width: "880px",
        "show-close": true,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "before-close": _vm.beforeClose,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "top" }, [
          _c("div", { staticClass: "tab-content" }, [
            _c(
              "div",
              { staticClass: "step-content flex_c_c", staticStyle: {} },
              [
                _c(
                  "div",
                  { class: { active: _vm.active > 0 } },
                  [
                    _c("svg-icon", {
                      attrs: { className: "icon", iconClass: "one" },
                    }),
                    _vm._v(" 输入主单号 "),
                  ],
                  1
                ),
                _c("svg-icon", {
                  class: { active: _vm.active > 1 },
                  attrs: { className: "icon arrow", iconClass: "arrow" },
                }),
                _c(
                  "div",
                  { class: { active: _vm.active > 1 } },
                  [
                    _c("svg-icon", {
                      attrs: { className: "icon", iconClass: "two" },
                    }),
                    _vm._v(" 选择HOLD类型 "),
                  ],
                  1
                ),
                _c("svg-icon", {
                  class: { active: _vm.active > 2 },
                  attrs: { className: "icon arrow", iconClass: "arrow" },
                }),
                _c(
                  "div",
                  { class: { active: _vm.active > 2 } },
                  [
                    _c("svg-icon", {
                      attrs: { className: "icon", iconClass: "three" },
                    }),
                    _vm._v(" 选择HOLD原因 "),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "header" },
          [
            _vm.active === 1
              ? _c(
                  "el-input",
                  {
                    staticClass: "input-search",
                    staticStyle: { width: "586px", "margin-top": "32px" },
                    attrs: { placeholder: "请输入内容", clearable: "" },
                    on: {
                      change: function ($event) {
                        _vm.result = false
                      },
                    },
                    model: {
                      value: _vm.billNo,
                      callback: function ($$v) {
                        _vm.billNo = $$v
                      },
                      expression: "billNo",
                    },
                  },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "searchbtn",
                        attrs: { slot: "append", icon: "el-icon-search" },
                        on: { click: _vm.search },
                        slot: "append",
                      },
                      [_vm._v("查询")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.active === 2
              ? _c("div", { staticClass: "holdType" }, [
                  _c("div", [
                    _vm._v(" 主单号："),
                    _c("span", { staticClass: "holdNo" }, [
                      _vm._v(_vm._s(_vm.billNo) + "  "),
                    ]),
                    _vm._v(" 选择HOLD类型： "),
                  ]),
                  _c("div", { staticClass: "type" }, [
                    _c(
                      "div",
                      {
                        class: [
                          "hodeType-item",
                          _vm.hodeActive === 0 ? "hodeActive" : "",
                        ],
                        on: {
                          click: function ($event) {
                            return _vm.handleSelectHode(0)
                          },
                        },
                      },
                      [
                        _vm._v(" 主单 "),
                        _vm.hodeActive === 0
                          ? _c("img", {
                              attrs: {
                                src: require("@/assets/images/gou.png"),
                                alt: "",
                              },
                            })
                          : _vm._e(),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        class: [
                          "hodeType-item",
                          _vm.hodeActive === 1 ? "hodeActive" : "",
                        ],
                        on: {
                          click: function ($event) {
                            return _vm.handleSelectHode(1)
                          },
                        },
                      },
                      [
                        _vm._v(" 分单 "),
                        _vm.hodeActive === 1
                          ? _c("img", {
                              attrs: {
                                src: require("@/assets/images/gou.png"),
                                alt: "",
                              },
                            })
                          : _vm._e(),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        class: [
                          "hodeType-item",
                          _vm.hodeActive === 2 ? "hodeActive" : "",
                        ],
                        on: {
                          click: function ($event) {
                            return _vm.handleSelectHode(2)
                          },
                        },
                      },
                      [
                        _vm._v(" 柜子 "),
                        _vm.hodeActive === 2
                          ? _c("img", {
                              attrs: {
                                src: require("@/assets/images/gou.png"),
                                alt: "",
                              },
                            })
                          : _vm._e(),
                      ]
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm.active === 3 ? _c("div") : _vm._e(),
          ],
          1
        ),
        _c("div", { staticClass: "data" }, [
          !_vm.result
            ? _c("img", {
                staticClass: "empty",
                attrs: {
                  src: require("@/assets/images/bill/empty.png"),
                  alt: "",
                },
              })
            : _c(
                "div",
                { staticClass: "result" },
                [
                  !_vm.state
                    ? [
                        _c("p", [
                          _vm._v(" 主单号："),
                          _c("span", { staticClass: "billNo" }, [
                            _vm._v(_vm._s(_vm.billNo)),
                          ]),
                          _vm._v(" 查询结果 "),
                        ]),
                        _c("p", [
                          _vm._v(" 此提单处于 "),
                          _c("span", { staticClass: "status" }, [
                            _vm._v(
                              _vm._s(_vm._f("filterStatus")(_vm.billData[0]))
                            ),
                          ]),
                          _vm._v(" 状态，不支持HOLD "),
                        ]),
                      ]
                    : _vm._e(),
                  _vm.hodeActive === 1 && _vm.active === 2
                    ? [
                        _c(
                          "div",
                          { staticClass: "filter" },
                          [
                            _c("span", [_vm._v("订单列表：")]),
                            _c(
                              "el-input",
                              { staticClass: "bill-search" },
                              [
                                _c("el-button", {
                                  attrs: {
                                    slot: "append",
                                    icon: "el-icon-search",
                                  },
                                  slot: "append",
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c("BaseTable", {
                          staticClass: "baseTable",
                          attrs: {
                            isPager: false,
                            border: true,
                            height: 200,
                            tableData: _vm.tableData,
                          },
                          on: {
                            handleSelectionChange: _vm.handleSelectionChange,
                          },
                        }),
                        _vm.selectionItem.length > 0
                          ? _c("p", { staticClass: "pager" }, [
                              _vm._v("已选 "),
                              _c("span", { staticClass: "pagerNum" }, [
                                _vm._v(_vm._s(_vm.selectionItem.length)),
                              ]),
                              _vm._v(" 项"),
                            ])
                          : _vm._e(),
                      ]
                    : _vm._e(),
                  _vm.active === 3
                    ? [
                        _c(
                          "el-form",
                          {
                            ref: "form",
                            staticClass: "form",
                            attrs: {
                              model: _vm.form,
                              rules: _vm.rules,
                              "label-position": "top",
                            },
                          },
                          [
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-col",
                                  { attrs: { span: 24 } },
                                  [
                                    _c(
                                      "el-form-item",
                                      {
                                        attrs: {
                                          label: "选择HOLD原因",
                                          prop: "reason",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-select",
                                          {
                                            staticStyle: { width: "100%" },
                                            attrs: {
                                              placeholder: "请选择选择HOLD原因",
                                            },
                                            model: {
                                              value: _vm.form.reason,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "reason",
                                                  $$v
                                                )
                                              },
                                              expression: "form.reason",
                                            },
                                          },
                                          _vm._l(_vm.options, function (item) {
                                            return _c("el-option", {
                                              key: item.value,
                                              attrs: {
                                                label: item.label,
                                                value: item.value,
                                              },
                                            })
                                          }),
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-row",
                              [
                                _vm.form.reason === "其他"
                                  ? _c(
                                      "el-col",
                                      { attrs: { span: 24 } },
                                      [
                                        _c(
                                          "el-form-item",
                                          {
                                            attrs: {
                                              label: "填写详细描述",
                                              prop: "reason1",
                                            },
                                          },
                                          [
                                            _c("el-input", {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                type: "textarea",
                                                rows: 4,
                                                placeholder:
                                                  "请输入详细描述内容",
                                              },
                                              model: {
                                                value: _vm.form.reason1,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "reason1",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.reason1",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              ),
        ]),
      ]),
      _c(
        "span",
        { staticClass: "footers", attrs: { slot: "footer" }, slot: "footer" },
        [
          _vm.result
            ? [
                _c(
                  "div",
                  { staticClass: "cancel flex_c_c", on: { click: _vm.cancel } },
                  [_vm._v(" " + _vm._s(_vm.state ? "上一步" : "关闭") + " ")]
                ),
                _vm.state
                  ? _c(
                      "div",
                      { staticClass: "ok flex_c_c", on: { click: _vm.ok } },
                      [_vm._v("下一步")]
                    )
                  : _vm._e(),
              ]
            : _vm._e(),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }