<template>
  <div>
    <Drawer :drawer="drawer" title="装载" :size="785" @close="close">
      <div class="view">
        <div class="sandway">
          <div>
            <span class="label">派送方式</span>
            <span class="value">
              {{ rowData.pickUpType === 1 ? `${tableData.columns[0]?.typeName}-${tableData.columns[0]?.code}` : '回仓' }}
            </span>
          </div>
          <div class="btn" @click="t6">
            <img
              style="vertical-align: middle"
              src="@/assets/images/bill/t6.png"
            />
            获取T6数据</div>
        </div>
        <div class="upload-area">
          <div class="content">
            <div class="btn" @click="dialogVisible1 = true">选择订单</div>
            <div class="btn" @click="dialogVisible = true">
              <img
                style="vertical-align: middle"
                src="@/assets/images/bill/upload.png"
              />
              导入订单
            </div>
            <div class="btn1" @click="download()">
              <img
                style="vertical-align: middle"
                src="@/assets/images/bill/download.png"
              />
              下载模版
            </div>
          </div>
        </div>
        <BaseTable
          style="margin-top: 16px"
          :height="480"
          :tableData="tableData"
          :isPager="false"
          :border="true"
        >
          <template slot="typeName" slot-scope="row">
            {{ row.scope.typeName }} {{ row.scope.code ? `- ${row.scope.code}` : '' }}
          </template>
          <template slot="qty" slot-scope="row">
            {{ row.scope.qty }}
            <span class="detail" @click="detail(row.scope)">明细</span>
          </template>
          <template slot="remarks" slot-scope="row">
            <template v-if="!row.scope.remarks">
              --
            </template>
            <template v-else>
              <el-popover
                placement="bottom-end"
                width="426"
                trigger="click">
                <img slot="reference" style="cursor: pointer;" src="@/assets/images/bill/message.png" />
                <div class="remark-area">
                  <div class="title">
                    <p class="lable">备注内容</p>
                    <p class="copy" @click="handleCopy(row.scope.remarks)">
                      <img
                        style="vertical-align: -4px"
                        src="@/assets/images/bill/copy1.png"
                      />复制
                    </p>
                  </div>
                  <div class="content">
                    {{ row.scope.remarks }}
                  </div>
                </div>
              </el-popover>
            </template>
          </template>
        </BaseTable>
      </div>
      <ExportOrderDialog
        :dialogVisible="dialogVisible"
        :sandwayObj="sandwayObj"
        :rowData="rowData"
        @ok="ok"
        @cancel="dialogVisible = false"
      ></ExportOrderDialog>
      <SelectOrderListDialog
        :dialogVisible="dialogVisible1"
        :sandwayObj="sandwayObj"
        :rowData="rowData"
        @ok="ok"
        @cancel="dialogVisible1 = false"
      >
      </SelectOrderListDialog>
      <OrderDetailDialog
        :dialogVisible="dialogVisible2"
        :orderData="orderData"
        :rowData="rowData"
        :sendWay="sendWay"
        @ok="ok"
        @cancel="dialogVisible2 = false">
      </OrderDetailDialog>
    </Drawer>
  </div>
</template>

<script>
const Drawer = () => import('@/components/drawer/Drawer')
import ExportOrderDialog from './ExportOrderDialog'
import SelectOrderListDialog from './SelectOrderListDialog'
import OrderDetailDialog from './OrderDetailDialog'
import { getMBLSendWay, getOrderDetailPages, onLoadMBL, searchOnLoadMBLT6 } from '@/api/bill'
const BaseTable = () => import('@/components/table/BaseTable')
import config from '@/utils/config'
export default {
  components: { Drawer, ExportOrderDialog, SelectOrderListDialog, BaseTable, OrderDetailDialog },
  filters: {
    formatterSendway(name) {
      const item = config.sendWayOptions.find((a) => a.value === name)
      return item?.label
    }
  },
  props: {
    drawer: {
      type: Boolean,
      default: false
    },
    rowData: {
      type: Object,
      default: () => {
        return {
          blNo: 'MATS123124'
        }
      }
    }
  },
  data() {
    return {
      config,
      dialogVisible: false,
      dialogVisible1: false,
      dialogVisible2: false,
      fileList: [],
      tableData: {
        fileds: [
          {
            filed: 'typeName',
            label: '处理方式',
            width: '',
            isCustom: true
          },
          {
            filed: 'qty',
            label: '订单件数',
            width: '',
            isCustom: true
          },
          {
            filed: 'remarks',
            label: '备注',
            width: '',
            isCustom: true
          }
        ],
        columns: [],
        total: 0,
        spanMethod: ({ row, column, rowIndex, columnIndex }) => {
          console.log(row, column, rowIndex, columnIndex)
          if (columnIndex === 0) {
            const rowMuti = []
            const index = this.tableData.columns.findIndex(
              (a) => a.typeName + a.code === row.typeName + row.code
            )
            if (index === rowIndex) {
              // 如果第一次出现
              for (let i = rowIndex; i < this.tableData.columns.length; i++) {
                if (this.tableData.columns[i].typeName + this.tableData.columns[i].code === row.typeName + row.code) {
                  rowMuti.push(this.tableData.columns[i])
                } else {
                  break
                }
              }
            }
            if (
              rowMuti.length > 0 ||
              rowIndex === this.tableData.columns.length - 1
            ) {
              return {
                rowspan: rowMuti.length || 1,
                colspan: 1
              }
            } else {
              return {
                rowspan: 0,
                colspan: 0
              }
            }
          }
        }
      },
      orderList: [],
      orderData: [],
      sendWay: 0
    }
  },
  computed: {
    sandwayObj() {
      if (this.rowData.pickUpType === 1) {
        return this.tableData.columns[0]
      } else {
        return {}
      }
    }
  },
  watch: {
    drawer(val) {
      if (val) {
        this.getSendWay()
      }
    }
  },
  methods: {
    async getSendWay() {
      try {
        const res = await getMBLSendWay({
          mblId: this.rowData.id
        })
        if (res.success) {
          this.tableData.columns = res.data
          if (res.data.length > 0) {
            this.getOrderList()
          }
        } else {
          this.$message.error(res.errorMessage)
        }
        console.log(res)
      } catch (error) {
        console.llog(error)
      }
    },
    async getOrderList() {
      try {
        const res = await getOrderDetailPages({
          pageIndex: 1,
          pageSize: 10000,
          queryParam: {
            id: this.rowData.id
          }
        })
        if (res.success) {
          this.orderList = res.data.list
        }
      } catch (error) {
        console.log(error)
      }
    },
    ok() {
      this.getSendWay()
      this.dialogVisible2 = false
      this.dialogVisible = false
      this.dialogVisible1 = false
    },
    download() {
      const elink = document.createElement('a')
      elink.style.display = 'none'
      const guid = 'cd4e9875f01a4b41ba8860c6150f6dbb'
      elink.href = `/backEnd/api/${config.supplyCode}/File/downLoadTemplate?guid=${guid}`
      document.body.appendChild(elink)
      elink.click()
      URL.revokeObjectURL(elink.href) // 释放URL 对象
      document.body.removeChild(elink)
    },
    close() {
      this.$emit('close')
    },
    detail(row) {
      console.log(row)
      const orderData = this.orderList.filter(a => a.mblSendWayId === row.id)
      this.orderData = orderData
      this.sendWay = row.type
      this.dialogVisible2 = true
    },
    // 提示内容复制
    handleCopy(val) {
      const url = val
      const oInput = document.createElement('input')
      oInput.value = url
      document.body.appendChild(oInput)
      oInput.select() // 选择对象;

      document.execCommand('Copy') // 执行浏览器复制命令
      this.$message({
        message: '复制成功',
        type: 'success'
      })
      oInput.remove()
    },
    async t6() {
        try {
        const params = {
            mblId: this.rowData?.id,
            operation: 2,
            orderItems: []
        }
        this.orderList.forEach(item => {
            params.orderItems.push({
                orderId: item.id,
                trackingNumber: item.trackingNumber
            })
        })
        const res = await onLoadMBL(params)
        if (res.success) {
          const res1 = await searchOnLoadMBLT6({ number: this.rowData.blNo })
          if (res1.success) {
            // this.$message.error('无订单数据')
          } else {
            this.$message.error(res1.errorMessage)
          }
        } else {
          this.$message.error(res.errorMessage)
        }
        console.log(res)
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.view {
  width: calc(100% - 80px);
  height: calc(100% - 48px);
  margin: 24px auto;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  .title {
    width: 36px;
    height: 24px;
    font-size: 18px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #25396f;
    line-height: 24px;
  }
  .sandway {
    margin-top: 24px;
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: space-between;
    .label {
      width: 56px;
      height: 40px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #25396f;
      line-height: 40px;
    }
    .value {
      display: inline-block;
      width: 240px;
      height: 40px;
      background: #f8fbff;
      border-radius: 4px 4px 4px 4px;
      opacity: 1;
      margin-left: 8px;
      font-size: 14px;
      font-weight: 400;
      color: #25396f;
      line-height: 40px;
      text-indent: 16px;
    }
    .btn {
      width: 120px;
      height: 40px;
      background: #9D67FE;
      border-radius: 4px 4px 4px 4px;
      opacity: 1;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 40px;
      text-align: center;
      cursor: pointer;
    }
  }
  .upload-area {
    width: 705px;
    height: 120px;
    background: #f8fbff;
    border-radius: 6px 6px 6px 6px;
    opacity: 1;
    border: 1px dashed #dbe4f2;
    margin: 16px auto 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .content {
      display: flex;
      .btn {
        width: 160px;
        height: 40px;
        background: #2395f3;
        border-radius: 4px 4px 4px 4px;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        line-height: 40px;
        cursor: pointer;
        text-align: center;
        margin-left: 16px;
      }
      .btn1 {
        width: 160px;
        height: 38px;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        border: 1px solid #2395f3;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #2395f3;
        line-height: 38px;
        cursor: pointer;
        text-align: center;
        margin-left: 16px;
      }
    }
  }
  .detail {
    color: #2395F3;
    margin-left: 20px;
    cursor: pointer;
  }
}

.remark-area {
  margin: 8px auto;
  width: 400px;
  height: 200px;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  border: 1px solid #EEEEEE;
  .title {
    width: 400px;
    height: 39px;
    background: #F7F7F7;
    border-radius: 4px 4px 0px 0px;
    opacity: 1;
    border-bottom: 1px solid #EEEEEE;
    display: flex;
    justify-content: space-between;
    .lable {
      margin-left: 20px;
      font-size: 12px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      line-height: 39px;
    }
    .copy {
      margin-right: 20px;
      font-size: 12px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #2395F3;
      line-height: 39px;
      cursor: pointer;
    }
  }
  .content {
    padding: 14px 20px;
    width: 360px;
    max-height: 132px;
    font-size: 12px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    line-height: 20px;
    overflow-y: auto;
  }
}
</style>
