var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [{ name: "drag", rawName: "v-drag" }],
      attrs: {
        title: `${!_vm.dialogType ? "确认" : "拒绝"}`,
        visible: _vm.dialogVisible,
        width: "680px",
        "append-to-body": true,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "before-close": _vm.cancel,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          staticClass: "form",
          attrs: { model: _vm.form, rules: _vm.rules, "label-position": "top" },
        },
        [
          _vm.dialogType
            ? _c(
                "el-form-item",
                { attrs: { prop: "reason", label: "拒绝原因" } },
                [
                  _c("base-select-common", {
                    ref: "BaseSelectCommon",
                    attrs: { width: "320px", type: 3, title: "拒绝原因" },
                    model: {
                      value: _vm.form.reason,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "reason", $$v)
                      },
                      expression: "form.reason",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c("el-form-item", { attrs: { label: "提单信息", required: "" } }, [
            _c(
              "div",
              { staticClass: "des" },
              [
                _c("el-row", { staticClass: "row" }, [
                  _c("p", [
                    _c("span", { staticClass: "label" }, [_vm._v("提单号")]),
                    _c("span", { staticClass: "data" }, [
                      _vm._v(_vm._s(_vm.billData.mblNo)),
                    ]),
                  ]),
                  _c("p", [
                    _c("span", { staticClass: "label" }, [_vm._v("提单类型")]),
                    _c("span", { staticClass: "data" }, [
                      _vm._v(
                        _vm._s(_vm.billData.classification ? "分单" : "直单")
                      ),
                    ]),
                  ]),
                ]),
                _c("el-row", { staticClass: "row" }, [
                  _c("p", [
                    _c("span", { staticClass: "label" }, [_vm._v("提单件数")]),
                    _c("span", { staticClass: "data" }, [
                      _vm._v(_vm._s(_vm.billData.qty)),
                    ]),
                  ]),
                  _c("p", [
                    _c("span", { staticClass: "label" }, [_vm._v("重量")]),
                    _c("span", { staticClass: "data" }, [
                      _vm._v(_vm._s(_vm.billData.weightKgs) + " KG"),
                    ]),
                  ]),
                ]),
                _c("el-row", { staticClass: "row" }, [
                  _c("p", [
                    _c("span", { staticClass: "label" }, [_vm._v("方数")]),
                    _c("span", { staticClass: "data" }, [
                      _vm._v(_vm._s(_vm.billData.weightCbm)),
                    ]),
                  ]),
                  _c("p", [
                    _c("span", { staticClass: "label" }, [_vm._v("订单重量")]),
                    _c("span", { staticClass: "data" }, [
                      _vm._v(_vm._s(_vm.billData.orderWeightKgs)),
                    ]),
                  ]),
                ]),
                _c("el-row", { staticClass: "row" }, [
                  _c("p", [
                    _c("span", { staticClass: "label" }, [_vm._v("订单件数")]),
                    _c("span", { staticClass: "data" }, [
                      _vm._v(_vm._s(_vm.billData.btns)),
                    ]),
                  ]),
                  _c("p", [
                    _c("span", { staticClass: "label" }, [_vm._v("业务范畴")]),
                    _c("span", { staticClass: "data" }, [
                      _c("span", { staticClass: "tips clearenceType" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("formatterClearenceType")(
                                _vm.billData.clearenceType
                              )
                            )
                        ),
                      ]),
                      _c("span", { staticClass: "tips pickUpType" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("formatterPickUpType")(
                              _vm.billData.pickUpType
                            )
                          )
                        ),
                      ]),
                    ]),
                  ]),
                ]),
              ],
              1
            ),
          ]),
          !_vm.dialogType
            ? _c(
                "el-row",
                { staticClass: "row" },
                [
                  _vm.billData.clearenceType
                    ? _c(
                        "el-form-item",
                        {
                          staticStyle: { "margin-bottom": "20px" },
                          attrs: {
                            prop: "clearencePriorityId",
                            label: "清关优先级",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "270px" },
                              attrs: { placeholder: "请选择", clearable: "" },
                              model: {
                                value: _vm.form.clearencePriorityId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "clearencePriorityId", $$v)
                                },
                                expression: "form.clearencePriorityId",
                              },
                            },
                            _vm._l(_vm.clearenceTypeOptions, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.billData.pickUpType
                    ? _c(
                        "el-form-item",
                        {
                          staticStyle: { "margin-bottom": "20px" },
                          attrs: {
                            prop: "pickUpPriorityId",
                            label: "提货优先级",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "270px" },
                              attrs: { placeholder: "请选择", clearable: "" },
                              model: {
                                value: _vm.form.pickUpPriorityId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "pickUpPriorityId", $$v)
                                },
                                expression: "form.pickUpPriorityId",
                              },
                            },
                            _vm._l(_vm.pickUpTypeOptions, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.billData.clearenceType
                    ? _c(
                        "el-form-item",
                        {
                          staticStyle: { "margin-bottom": "20px" },
                          attrs: {
                            prop: "supplierClearenceId",
                            label: "选择清关行",
                          },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "270px" },
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.form.supplierClearenceId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "supplierClearenceId", $$v)
                                },
                                expression: "form.supplierClearenceId",
                              },
                            },
                            _vm._l(_vm.clearenceOptions, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.billData.pickUpType
                    ? _c(
                        "el-form-item",
                        {
                          staticStyle: { "margin-bottom": "20px" },
                          attrs: { prop: "supplierId", label: "选择提货车行" },
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "270px" },
                              attrs: { placeholder: "请选择" },
                              model: {
                                value: _vm.form.supplierId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "supplierId", $$v)
                                },
                                expression: "form.supplierId",
                              },
                            },
                            _vm._l(_vm.pickUpOptions, function (item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "dbValue", label: "差异率" } },
                    [
                      _c(
                        "div",
                        { staticClass: "warn" },
                        [
                          _vm._v(" " + _vm._s(_vm.varianceRate) + " "),
                          _c(
                            "el-tooltip",
                            {
                              staticClass: "item",
                              attrs: {
                                effect: "dark",
                                content: "",
                                placement: "top",
                              },
                            },
                            [
                              _c(
                                "div",
                                { attrs: { slot: "content" }, slot: "content" },
                                [
                                  _vm._v("提单重量与绑定订单重量差异率"),
                                  _c("br"),
                                  _vm._v("超过设定值，提单确认时提示"),
                                ]
                              ),
                              _c("img", {
                                staticClass: "suggest",
                                attrs: {
                                  src: require("@/assets/images/bill/suggest.png"),
                                },
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "span",
        { staticClass: "footers", attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "div",
            { staticClass: "cancel flex_c_c", on: { click: _vm.cancel } },
            [_vm._v("取 消")]
          ),
          _c(
            "div",
            {
              staticClass: "ok flex_c_c",
              on: {
                click: function ($event) {
                  return _vm.ok("form")
                },
              },
            },
            [_vm._v("确 定")]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }