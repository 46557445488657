var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [{ name: "drag", rawName: "v-drag" }],
      attrs: {
        title: "DO代理管理",
        visible: _vm.dialogVisible,
        width: "1200px",
        "append-to-body": true,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "before-close": _vm.cancel,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c("div", { staticClass: "content" }, [
        _c(
          "div",
          { staticClass: "opt" },
          [
            _c(
              "div",
              {
                staticClass: "add",
                on: {
                  click: function ($event) {
                    _vm.dialogVisible2 = true
                  },
                },
              },
              [
                _c("svg-icon", {
                  attrs: { className: "icon", iconClass: "add" },
                }),
                _vm._v(" 新增 "),
              ],
              1
            ),
            _c("el-input", {
              staticStyle: { width: "320px" },
              attrs: { placeholder: "请输入模板名称" },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "data" },
          [
            _c("BaseTable", {
              attrs: {
                height: 440,
                isPager: false,
                border: true,
                tableData: _vm.tableData,
                loading: _vm.loading,
              },
              scopedSlots: _vm._u([
                {
                  key: "opt",
                  fn: function (row) {
                    return [
                      _c("div", [
                        _c("i", {
                          staticClass: "el-icon-edit-outline icon1",
                          staticStyle: { color: "#2395f3", cursor: "pointer" },
                          on: {
                            click: function ($event) {
                              return _vm.editRow(row.scope)
                            },
                          },
                        }),
                        _c("i", {
                          staticClass: "el-icon-delete icon1",
                          staticStyle: { color: "#2395f3", cursor: "pointer" },
                          on: {
                            click: function ($event) {
                              return _vm.deleteRow(row.scope)
                            },
                          },
                        }),
                      ]),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
      ]),
      _c("AddDoTplDialog", {
        attrs: { dialogVisible: _vm.dialogVisible2, editData: _vm.editData },
        on: {
          ok: function ($event) {
            _vm.dialogVisible2 = false
          },
          cancel: function ($event) {
            _vm.dialogVisible2 = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }