var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [{ name: "drag", rawName: "v-drag" }],
      attrs: {
        title: "新增跟进记录",
        visible: _vm.dialogVisible,
        width: "690px",
        "append-to-body": true,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "before-close": _vm.cancel,
        top: "5vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "form",
              attrs: { model: _vm.form, rules: _vm.rules },
            },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "mblSendWayId", label: "派送方式" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "315px" },
                              attrs: { placeholder: "请选择类别" },
                              model: {
                                value: _vm.form.mblSendWayId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "mblSendWayId", $$v)
                                },
                                expression: "form.mblSendWayId",
                              },
                            },
                            _vm._l(_vm.sandWayOptions, function (item, $index) {
                              return _c("el-option", {
                                key: $index,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "type", label: "类型" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "315px" },
                              attrs: { placeholder: "请选择类型" },
                              model: {
                                value: _vm.form.type,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "type", $$v)
                                },
                                expression: "form.type",
                              },
                            },
                            _vm._l(
                              _vm.config.sandWayTypeOptions,
                              function (item, $index) {
                                return _c("el-option", {
                                  key: $index,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "remark", label: "描述" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "650px" },
                        attrs: { placeholder: "请输入描述" },
                        model: {
                          value: _vm.form.remark,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "remark", $$v)
                          },
                          expression: "form.remark",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "table" },
                [
                  _c(
                    "div",
                    { staticClass: "search" },
                    [
                      _c("span", { staticClass: "label" }, [
                        _vm._v("选择订单"),
                      ]),
                      _c("el-input", {
                        staticStyle: { width: "240px" },
                        attrs: {
                          placeholder: "请输入内容",
                          "prefix-icon": "el-icon-search",
                        },
                        model: {
                          value: _vm.text,
                          callback: function ($$v) {
                            _vm.text = $$v
                          },
                          expression: "text",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("BaseTable", {
                    staticStyle: { "margin-top": "10px" },
                    attrs: {
                      height: "360",
                      tableData: { ..._vm.tableData, columns: _vm.columns },
                      isPager: false,
                      border: true,
                    },
                    on: { handleSelectionChange: _vm.handleSelectionChange },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "table-footer" }, [
                _c("span", [
                  _vm._v("已选择 "),
                  _c(
                    "span",
                    { staticStyle: { color: "rgba(35, 149, 243, 1)" } },
                    [_vm._v(_vm._s(_vm.selection.length))]
                  ),
                  _vm._v(" 项"),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        { staticClass: "footers", attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "div",
            { staticClass: "cancel flex_c_c", on: { click: _vm.cancel } },
            [_vm._v("取 消")]
          ),
          _c(
            "div",
            {
              staticClass: "ok flex_c_c",
              on: {
                click: function ($event) {
                  return _vm.ok("form")
                },
              },
            },
            [_vm._v("确 定")]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }