var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Drawer",
    {
      attrs: {
        drawer: _vm.drawer,
        title: "跟进记录",
        withHeader: false,
        size: 1130,
      },
      on: { close: _vm.close },
    },
    [
      _c(
        "div",
        { staticClass: "view" },
        [
          _c("div", { staticClass: "title" }, [
            _c("div", { staticClass: "title_left" }, [
              _c("div", { staticClass: "title-main" }, [
                _c("img", {
                  attrs: { src: require("@/assets/images/bill/record-1.png") },
                }),
                _vm._v("FMS跟进记录 "),
              ]),
              _c("div", { staticClass: "copy-no" }, [
                _c("span", [_vm._v("提单号：" + _vm._s(_vm.rowData.blNo))]),
                _c("img", {
                  attrs: {
                    src: require("@/assets/images/bill/copy.png"),
                    title: "复制",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.copy(_vm.rowData.blNo)
                    },
                  },
                }),
              ]),
            ]),
            _c("div", { staticClass: "title_right" }, [
              _c("i", {
                staticClass: "el-icon-close icon",
                on: { click: _vm.close },
              }),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "container" },
            [
              _c("div", { staticClass: "opt" }, [
                _c(
                  "div",
                  {
                    staticClass: "btn",
                    on: {
                      click: function ($event) {
                        _vm.dialogVisible = true
                      },
                    },
                  },
                  [
                    _c("img", {
                      attrs: { src: require("@/assets/images/bill/add.png") },
                    }),
                    _vm._v(" 新增 "),
                  ]
                ),
              ]),
              _c("BaseTable", {
                staticClass: "table",
                attrs: {
                  height: "460",
                  tableData: _vm.tableData,
                  isPager: false,
                },
                scopedSlots: _vm._u([
                  {
                    key: "createdOn",
                    fn: function (row) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("formatterDate")(row.scope.createdOn)
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                  {
                    key: "orderQty",
                    fn: function (row) {
                      return [
                        _vm._v(" " + _vm._s(row.scope.orderQty) + " "),
                        _c(
                          "span",
                          {
                            staticClass: "view-data",
                            on: {
                              click: function ($event) {
                                return _vm.viewOrder(row.scope)
                              },
                            },
                          },
                          [_vm._v("查看")]
                        ),
                      ]
                    },
                  },
                  {
                    key: "fileQty",
                    fn: function (row) {
                      return [
                        _vm._v(" " + _vm._s(row.scope.fileQty) + " "),
                        _c(
                          "span",
                          {
                            staticClass: "view-data",
                            on: {
                              click: function ($event) {
                                return _vm.viewFile(row.scope)
                              },
                            },
                          },
                          [_vm._v("查看")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("AddFmsFollowUpDialog", {
            attrs: { dialogVisible: _vm.dialogVisible, rowData: _vm.rowData },
            on: {
              ok: _vm.ok,
              cancel: function ($event) {
                _vm.dialogVisible = false
              },
            },
          }),
          _c("FmsFollowUpFileDialog", {
            attrs: {
              dialogFormVisible: _vm.dialogVisible2,
              mblId: _vm.rowData.id,
              fileList: _vm.fileList,
            },
            on: {
              ok: function ($event) {
                _vm.dialogVisible2 = false
              },
            },
          }),
          _c("FmsFollowUpOrderDialog", {
            attrs: {
              dialogFormVisible: _vm.dialogVisible3,
              orderList: _vm.orderList,
            },
            on: {
              ok: function ($event) {
                _vm.dialogVisible3 = false
              },
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }