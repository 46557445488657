var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [{ name: "drag", rawName: "v-drag" }],
      attrs: {
        visible: _vm.dialogVisible,
        width: "728px",
        "show-close": true,
        title: "自提-卡派-订单明细",
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "before-close": _vm.beforeClose,
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "container" },
        [
          _c(
            "div",
            { staticClass: "search" },
            [
              _c("el-input", {
                staticStyle: { width: "200px" },
                attrs: {
                  size: "mini",
                  placeholder: "请输入单号搜索",
                  "suffix-icon": "el-icon-search",
                },
                model: {
                  value: _vm.text,
                  callback: function ($$v) {
                    _vm.text = $$v
                  },
                  expression: "text",
                },
              }),
            ],
            1
          ),
          _c("BaseTable", {
            staticStyle: { "margin-top": "16px" },
            attrs: {
              height: 440,
              tableData: {
                ..._vm.tableData,
                columns: _vm.columns,
              },
              isPager: false,
              border: true,
            },
            on: { handleSelectionChange: _vm.selectItems },
          }),
          _c("div", { staticClass: "num" }, [
            _c("span", { staticClass: "sum" }, [
              _vm._v("已选择 "),
              _c("span", { staticStyle: { color: "rgba(35, 149, 243, 1)" } }, [
                _vm._v(_vm._s(_vm.selectArr.length) + " "),
              ]),
              _vm._v(" 项"),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "span",
        { staticClass: "footer", attrs: { slot: "footer" }, slot: "footer" },
        [
          _c("div", { staticClass: "btn cancel", on: { click: _vm.cancel } }, [
            _vm._v("取消"),
          ]),
          _c("div", { staticClass: "btn ok", on: { click: _vm.deleteRow } }, [
            _c("i", { staticClass: "el-icon-delete" }),
            _vm._v(" 删除"),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }