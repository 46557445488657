<template>
  <el-dialog
    v-drag
    title="到仓"
    :visible.sync="dialogVisible"
    width="1000px"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
  >
    <div class="top">
      <span>首次到仓时间: </span>
      <el-date-picker
        v-model="picArrivedDate"
        type="datetime"
        placeholder="选择首次到仓时间"
        value-format="yyyy-MM-dd HH:mm:ss">
      </el-date-picker>
    </div>
    <div class="content">
      <ul class="row">
        <li class="row header">
          <div class="cell1">派送类型</div>
          <div class="cell2">提单件数</div>
          <div class="cell3">已到仓数</div>
          <div class="cell4">本次到仓数</div>
          <div class="cell5">到仓备注</div>
        </li>
        <div class="data">
          <li v-for="(item,$index) in dataList" :key="$index" class="row bodyRow">
            <div class="cell1">
              <span>{{ item.code ? `${item.typeName}-${item.code}` : item.typeName }}</span>
            </div>
            <div class="cell2">
              <span>{{ item.qty }}</span>
            </div>
            <div class="cell3">
              <span>{{ item.arrivalQty }}</span>
            </div>
            <div class="cell4">
              <el-input v-model="item.thisTimeArrivalQty" />
            </div>
            <div class="cell5">
              <el-input v-model="item.arrivalRemarks" />
            </div>
          </li>
        </div>
      </ul>
    </div>
    <span slot="footer" class="footers">
      <div class="cancel flex_c_c" @click="cancel">取 消</div>
      <div class="ok flex_c_c" @click="ok">确 定</div>
    </span>
  </el-dialog>
</template>
<script>
import { getFacilityDetail, arrivedFacility } from '@/api/bill'
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    rowData: {
      type: Object,
      default: () => { return {} }
    }
  },
  data() {
    return {
      arriveList: [],
      dataList: [],
      picArrivedDate: ''
    }
  },
  watch: {
    async dialogVisible(val) {
      if (val) {
        this.picArrivedDate = this.rowData.picArrivedDate || ''
        try {
          const result = await getFacilityDetail({ mblId: this.rowData.id })
          if (result.success) {
            this.dataList = result.data.arrivedDetail.map(a => {
              return {
                ...a,
                thisTimeArrivalQty: '',
                arrivalRemarks: ''
              }
            })
          } else {
            this.$message.error(result.errorMessage)
          }
        } catch (error) {
          console.error(error)
        }
      }
    }
  },
  methods: {
    cancel() {
      this.$emit('cancel')
    },
    async ok() {
      let flag = true
      for (let i = 0; i < this.dataList.length; i++) {
        const thisTimeArrivalQty = this.dataList[i].thisTimeArrivalQty
        const total = this.dataList[i].arrivalQty + +thisTimeArrivalQty
        if (total > this.dataList[i].qty) {
          this.$message.error(`第${i + 1}行，已到仓数加本次到仓数不能超过提单件数。`)
          flag = false
          break
        } else if (thisTimeArrivalQty === '' || thisTimeArrivalQty === '0') {
          this.$message.error(`第${i + 1}行，本次到仓数不能为空或0。`)
          flag = false
          break
        }
      }
      if (!this.picArrivedDate) {
        this.$message.error('必须填写首次到仓时间')
        return
      }
      if (flag) {
        try {
          const result = await arrivedFacility({ mblId: this.rowData.id, picArrivedDate: this.picArrivedDate, arrivedDetails: this.dataList })
          if (result.success) {
            this.$message.success('到仓成功')
            this.$emit('ok')
          } else {
            this.$message.error(result.errorMessage)
          }
        } catch (error) {
          console.error(error)
        }
      }
    }
  }
}
</script>
<style lang="less" scoped>
/deep/ .el-dialog__body {
  padding: 20px 20px;
}
.footers {
  width: 100%;
  display: flex;
  justify-content: center;
  .cancel {
    width: 120px;
    height: 40px;
    background: #EEEEEE;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    margin-right: 16px;
    cursor: pointer;
  }
  .ok {
    width: 120px;
    height: 40px;
    background: #2395f3;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    cursor: pointer;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
}
.top {
  margin-bottom: 10px;
  span {
    margin-right: 10px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    line-height: 40px;
  }
}
.content {
    width: 100%;
    max-height: 320px;
    border: 1px solid #EEEEEE;
    border-radius: 4px;
    overflow: hidden;
    .data {
      width: 100%;
      min-height: 280px;
      max-height: 280px;
      overflow-y: auto;
    }
    .row {
        width: 100%;
        height: auto;
    }
    .bodyRow {
        width: 960px;
        height: 40px;
        background: #F7F7F7;
        opacity: 1;
        border-bottom: 1px solid #EEEEEE;
        display: flex;
        font-size: 12px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        line-height: 40px;
        text-align: center;
        &:last-child {
            border-bottom: 1px solid #EEEEEE;
        }
    }
    .header {
        width: 960px;
        height: 40px;
        background: #F7F7F7;
        opacity: 1;
        border-bottom: 1px solid #EEEEEE;
        display: flex;
        font-size: 12px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        line-height: 40px;
        text-align: center;
    }
    .cell1 {
        width: 200px;
        border-right: 1px solid #EEEEEE;
    }
    .cell2 {
        width: 150px;
        border-right: 1px solid #EEEEEE;
    }
    .cell3 {
        width: 150px;
        border-right: 1px solid #EEEEEE;
    }
    .cell4 {
        width: 150px;
        border-right: 1px solid #EEEEEE;
    }
    .cell5 {
        width: 322px;
        border-right: 1px solid #EEEEEE;
    }
    .add {
        color: #2395F3;
        cursor: pointer;
    }
    .delete {
        background: #ffffff;
    }
    .icon {
        color: #2395F3;
        font-size: 16px;
    }
    :deep(.el-input__inner) {
        border: 0;
    }
}
</style>
