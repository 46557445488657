var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "Drawer",
        {
          attrs: { drawer: _vm.drawer, title: "装载", size: 785 },
          on: { close: _vm.close },
        },
        [
          _c(
            "div",
            { staticClass: "view" },
            [
              _c("div", { staticClass: "sandway" }, [
                _c("div", [
                  _c("span", { staticClass: "label" }, [_vm._v("派送方式")]),
                  _c("span", { staticClass: "value" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.rowData.pickUpType === 1
                            ? `${_vm.tableData.columns[0]?.typeName}-${_vm.tableData.columns[0]?.code}`
                            : "回仓"
                        ) +
                        " "
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "btn", on: { click: _vm.t6 } }, [
                  _c("img", {
                    staticStyle: { "vertical-align": "middle" },
                    attrs: { src: require("@/assets/images/bill/t6.png") },
                  }),
                  _vm._v(" 获取T6数据"),
                ]),
              ]),
              _c("div", { staticClass: "upload-area" }, [
                _c("div", { staticClass: "content" }, [
                  _c(
                    "div",
                    {
                      staticClass: "btn",
                      on: {
                        click: function ($event) {
                          _vm.dialogVisible1 = true
                        },
                      },
                    },
                    [_vm._v("选择订单")]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "btn",
                      on: {
                        click: function ($event) {
                          _vm.dialogVisible = true
                        },
                      },
                    },
                    [
                      _c("img", {
                        staticStyle: { "vertical-align": "middle" },
                        attrs: {
                          src: require("@/assets/images/bill/upload.png"),
                        },
                      }),
                      _vm._v(" 导入订单 "),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "btn1",
                      on: {
                        click: function ($event) {
                          return _vm.download()
                        },
                      },
                    },
                    [
                      _c("img", {
                        staticStyle: { "vertical-align": "middle" },
                        attrs: {
                          src: require("@/assets/images/bill/download.png"),
                        },
                      }),
                      _vm._v(" 下载模版 "),
                    ]
                  ),
                ]),
              ]),
              _c("BaseTable", {
                staticStyle: { "margin-top": "16px" },
                attrs: {
                  height: 480,
                  tableData: _vm.tableData,
                  isPager: false,
                  border: true,
                },
                scopedSlots: _vm._u([
                  {
                    key: "typeName",
                    fn: function (row) {
                      return [
                        _vm._v(
                          " " +
                            _vm._s(row.scope.typeName) +
                            " " +
                            _vm._s(
                              row.scope.code ? `- ${row.scope.code}` : ""
                            ) +
                            " "
                        ),
                      ]
                    },
                  },
                  {
                    key: "qty",
                    fn: function (row) {
                      return [
                        _vm._v(" " + _vm._s(row.scope.qty) + " "),
                        _c(
                          "span",
                          {
                            staticClass: "detail",
                            on: {
                              click: function ($event) {
                                return _vm.detail(row.scope)
                              },
                            },
                          },
                          [_vm._v("明细")]
                        ),
                      ]
                    },
                  },
                  {
                    key: "remarks",
                    fn: function (row) {
                      return [
                        !row.scope.remarks
                          ? [_vm._v(" -- ")]
                          : [
                              _c(
                                "el-popover",
                                {
                                  attrs: {
                                    placement: "bottom-end",
                                    width: "426",
                                    trigger: "click",
                                  },
                                },
                                [
                                  _c("img", {
                                    staticStyle: { cursor: "pointer" },
                                    attrs: {
                                      slot: "reference",
                                      src: require("@/assets/images/bill/message.png"),
                                    },
                                    slot: "reference",
                                  }),
                                  _c("div", { staticClass: "remark-area" }, [
                                    _c("div", { staticClass: "title" }, [
                                      _c("p", { staticClass: "lable" }, [
                                        _vm._v("备注内容"),
                                      ]),
                                      _c(
                                        "p",
                                        {
                                          staticClass: "copy",
                                          on: {
                                            click: function ($event) {
                                              return _vm.handleCopy(
                                                row.scope.remarks
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("img", {
                                            staticStyle: {
                                              "vertical-align": "-4px",
                                            },
                                            attrs: {
                                              src: require("@/assets/images/bill/copy1.png"),
                                            },
                                          }),
                                          _vm._v("复制 "),
                                        ]
                                      ),
                                    ]),
                                    _c("div", { staticClass: "content" }, [
                                      _vm._v(
                                        " " + _vm._s(row.scope.remarks) + " "
                                      ),
                                    ]),
                                  ]),
                                ]
                              ),
                            ],
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("ExportOrderDialog", {
            attrs: {
              dialogVisible: _vm.dialogVisible,
              sandwayObj: _vm.sandwayObj,
              rowData: _vm.rowData,
            },
            on: {
              ok: _vm.ok,
              cancel: function ($event) {
                _vm.dialogVisible = false
              },
            },
          }),
          _c("SelectOrderListDialog", {
            attrs: {
              dialogVisible: _vm.dialogVisible1,
              sandwayObj: _vm.sandwayObj,
              rowData: _vm.rowData,
            },
            on: {
              ok: _vm.ok,
              cancel: function ($event) {
                _vm.dialogVisible1 = false
              },
            },
          }),
          _c("OrderDetailDialog", {
            attrs: {
              dialogVisible: _vm.dialogVisible2,
              orderData: _vm.orderData,
              rowData: _vm.rowData,
              sendWay: _vm.sendWay,
            },
            on: {
              ok: _vm.ok,
              cancel: function ($event) {
                _vm.dialogVisible2 = false
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }