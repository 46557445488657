<template>
  <el-dialog
    v-drag
    :title="`${!dialogType ? '确认' : '拒绝'}`"
    :visible.sync="dialogVisible"
    width="680px"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
  >
    <el-form
      ref="form"
      class="form"
      :model="form"
      :rules="rules"
      label-position="top"
    >
      <el-form-item v-if="dialogType" prop="reason" label="拒绝原因">
        <base-select-common
          ref="BaseSelectCommon"
          v-model="form.reason"
          width="320px"
          :type="3"
          title="拒绝原因"
        ></base-select-common>
      </el-form-item>
      <el-form-item label="提单信息" required>
        <div class="des">
          <el-row class="row">
            <p>
              <span class="label">提单号</span>
              <span class="data">{{ billData.mblNo }}</span>
            </p>
            <p>
              <span class="label">提单类型</span>
              <span class="data">{{
                billData.classification ? "分单" : "直单"
              }}</span>
            </p>
          </el-row>
          <el-row class="row">
            <p>
              <span class="label">提单件数</span>
              <span class="data">{{ billData.qty }}</span>
            </p>
            <p>
              <span class="label">重量</span>
              <span class="data">{{ billData.weightKgs }} KG</span>
            </p>
          </el-row>
          <el-row class="row">
            <p>
              <span class="label">方数</span>
              <span class="data">{{ billData.weightCbm }}</span>
            </p>
            <p>
              <span class="label">订单重量</span>
              <span class="data">{{ billData.orderWeightKgs }}</span>
            </p>
          </el-row>
          <el-row class="row">
            <p>
              <span class="label">订单件数</span>
              <span class="data">{{ billData.btns }}</span>
            </p>
            <p>
              <span class="label">业务范畴</span>
              <span class="data">
                <span class="tips clearenceType">
                  {{ billData.clearenceType | formatterClearenceType }}</span
                  >
                <span class="tips pickUpType">{{
                  billData.pickUpType | formatterPickUpType
                }}</span></span
              >
            </p>
          </el-row>
        </div>
      </el-form-item>
      <el-row v-if="!dialogType" class="row">
        <el-form-item v-if="billData.clearenceType" prop="clearencePriorityId" label="清关优先级" style="margin-bottom: 20px;">
          <el-select
            v-model="form.clearencePriorityId"
            style="width: 270px"
            placeholder="请选择"
            clearable
          >
            <el-option
              v-for="item in clearenceTypeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="billData.pickUpType" prop="pickUpPriorityId" label="提货优先级" style="margin-bottom: 20px;">
          <el-select
            v-model="form.pickUpPriorityId"
            style="width: 270px"
            placeholder="请选择"
            clearable
          >
            <el-option
              v-for="item in pickUpTypeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="billData.clearenceType" prop="supplierClearenceId" label="选择清关行" style="margin-bottom: 20px;">
          <el-select
            v-model="form.supplierClearenceId"
            style="width: 270px"
            placeholder="请选择"
          >
            <el-option
              v-for="item in clearenceOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="billData.pickUpType" prop="supplierId" label="选择提货车行" style="margin-bottom: 20px;">
          <el-select
            v-model="form.supplierId"
            style="width: 270px"
            placeholder="请选择"
          >
            <el-option
              v-for="item in pickUpOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="dbValue" label="差异率">
          <div class="warn">
            {{ varianceRate }}
            <el-tooltip
              class="item"
              effect="dark"
              content=""
              placement="top"
            >
              <div slot="content">提单重量与绑定订单重量差异率<br />超过设定值，提单确认时提示</div>
              <img class="suggest" src="@/assets/images/bill/suggest.png" />
            </el-tooltip>
          </div>
        </el-form-item>
      </el-row>
    </el-form>
    <span slot="footer" class="footers">
      <div class="cancel flex_c_c" @click="cancel">取 消</div>
      <div class="ok flex_c_c" @click="ok('form')">确 定</div>
    </span>
  </el-dialog>
</template>

<script>
const BaseSelectCommon = () => import('@/components/base/BaseSelectCommon.vue')
import { confirmMBLInfo, refuseMBL, confirmMBL } from '@/api/bill'
import { getSupplierDropDownList } from '@/api/system'
export default {
  components: { BaseSelectCommon },
  filters: {
    formatterClearenceType(val) {
      if (val) {
        return '平台清'
      } else {
        return '自清'
      }
    },
    formatterPickUpType(val) {
      const obj = {
        0: '自提',
        1: '直送',
        2: '回仓'
      }
      return obj[val]
    }
  },
  props: {
    dialogType: {
      type: Number,
      default: 0 // 0确认 1拒绝
    },
    mblId: {
      type: String,
      default: ''
    },
    dialogVisible: {
      type: Boolean,
      default: false
    },
    priorityList: {
      type: Array,
      default: function() {
        return []
      }
    }
  },
  data() {
    return {
      refuseReason: '',
      form: {
        pickUpPriorityId: '',
        clearencePriorityId: '',
        supplierId: '',
        supplierClearenceId: '',
        reason: ''
      },
      rules: {
        // pickUpPriorityId: [{ required: true, message: '请选择清关优先级', trigger: 'change' }],
        // clearencePriorityId: [{ required: true, message: '请选择提货优先级', trigger: 'change' }],
        reason: [{ required: true, message: '请选择拒绝原因', trigger: 'change' }],
        supplierId: [{ required: true, message: '请选择提货车行', trigger: 'change' }],
        supplierClearenceId: [{ required: true, message: '请选择清关车行', trigger: 'change' }]
      },
      billData: {
      },
      pickUpOptions: [],
      clearenceOptions: []
    }
  },
  computed: {
    clearenceTypeOptions() {
      return this.priorityList
        .filter((a) => a.type === 0)
        .map((item) => {
          return {
            value: item.id,
            label: item.contents
          }
        })
    },
    pickUpTypeOptions() {
      return this.priorityList
        .filter((a) => a.type === 1)
        .map((item) => {
          return {
            value: item.id,
            label: item.contents
          }
        })
    },
    varianceRate() {
      return `${(
        ((this.billData.weightKgs - this.billData.orderWeightKgs) /
          this.billData.weightKgs) *
        100
      ).toFixed(2)}%`
    }
  },
  watch: {
    async dialogVisible(val) {
      if (val) {
        try {
          const res = await confirmMBLInfo({
            mblId: this.mblId
          })
          if (res.success) {
            this.billData = res.data
          } else {
            this.$message.error(res.errorMessage)
          }
          const result1 = await getSupplierDropDownList({ scopValue: 'T' })
          if (result1.success) {
            this.pickUpOptions = result1.data
          } else {
            this.$message.error(result1.errorMessage)
          }
          const result2 = await getSupplierDropDownList({ scopValue: 'Q' })
          if (result2.success) {
            this.clearenceOptions = result2.data
          } else {
            this.$message.error(result2.errorMessage)
          }
        } catch (error) {
          console.log(error)
        }
      }
    }
  },
  methods: {
    cancel() {
      this.$emit('cancel')
    },
    ok(formName) {
      this.$refs[formName].validate(async(valid) => {
        if (valid) {
            const params = {
              mblId: this.mblId,
              supplierId: this.form.supplierId,
              supplierClearenceId: this.form.supplierClearenceId
            }
          if (!this.dialogType) {
            params.clearencePriorityId = this.form.clearencePriorityId
            params.pickUpPriorityId = this.form.pickUpPriorityId
            const res = await confirmMBL(params)
            if (res.success) {
              this.$message.success(res.errorMessage)
              this.$emit('ok')
              this.$refs[formName].resetFields()
            } else {
              this.$message.error(res.errorMessage)
            }
          } else {
            params.reason = this.$refs.BaseSelectCommon.getValueLable(this.form.reason)
            const res = await refuseMBL(params)
            if (res.success) {
              this.$message.success(res.errorMessage)
              this.$emit('ok')
              this.$refs[formName].resetFields()
            } else {
              this.$message.error(res.errorMessage)
            }
          }
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.form {
  width: 560px;
  margin: 0 auto;
  .des {
    width: 520px;
    height: 120px;
    padding: 20px;
    background: #f7f7f7;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    border: 1px solid #eeeeee;
  }
  .row {
    display: flex;
    flex-wrap: wrap;
    &:deep(.el-form-item):nth-child(2n) {
      margin-left: 15px;
    }
    p {
      width: 50%;
      height: 19px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      line-height: 20px;
    }
    & + .row {
      margin-top: 15px;
    }
    .label {
      display: inline-block;
      width: 59px;
    }
    .data {
      margin-left: 17px;
      color: #666666;
    }
    .tips {
      border-radius: 4px 4px 4px 4px;
      width: auto;
      height: 24px;
      font-size: 12px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      line-height: 24px;
      display: inline-block;
      padding: 0 4px;
      box-sizing: border-box;
      & + .tips {
        margin-left: 10px;
      }
    }
    .clearenceType {
      background: #2395f3;
    }
    .pickUpType {
      background: #35ba83;
    }
    .warn {
      width: 270px;
      height: 40px;
      background: rgba(254, 69, 61, 0.1);
      border-radius: 4px 4px 4px 4px;
      font-size: 16px;
      font-family: Microsoft YaHei-Bold, Microsoft YaHei;
      font-weight: bold;
      color: #fe453d;
      line-height: 40px;
      text-align: center;
      position: relative;
    }
    .suggest {
      width: 24px;
      height: 24px;
      position: absolute;
      right: 15px;
      top: 8px;
    }
  }
}
:deep(.el-dialog__body) {
  padding-top: 0;
}

.footers {
  width: 100%;
  display: flex;
  justify-content: center;
  .cancel {
    width: 120px;
    height: 40px;
    background: #EEEEEE;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    margin-right: 16px;
    cursor: pointer;
  }
  .ok {
    width: 120px;
    height: 40px;
    background: #2395f3;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    cursor: pointer;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
}
</style>
