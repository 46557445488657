var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [{ name: "drag", rawName: "v-drag" }],
      attrs: {
        title: "添加GGT抬头信息",
        visible: _vm.dialogVisible,
        "append-to-body": true,
        width: "800px",
        "show-close": true,
        top: "5vh",
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "before-close": _vm.cancel,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "dialog-content" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-position": "top",
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "公司名称", prop: "name" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "100%" },
                            attrs: { placeholder: "请输入公司名称" },
                            model: {
                              value: _vm.form.name,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "name", $$v)
                              },
                              expression: "form.name",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "国家", prop: "countryId" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "320px" },
                              attrs: { placeholder: "请选择国家" },
                              model: {
                                value: _vm.form.countryId,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "countryId", $$v)
                                },
                                expression: "form.countryId",
                              },
                            },
                            _vm._l(_vm.addressDropCodeOption, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "postalCode", label: "邮编" } },
                        [
                          _c("el-autocomplete", {
                            staticStyle: { width: "320px" },
                            attrs: {
                              placeholder: "仅限填写数字和英文字符，最多12位",
                              maxlength: 12,
                              "fetch-suggestions": _vm.querySearchAsync,
                              "trigger-on-focus": false,
                            },
                            on: { select: _vm.handleSelect },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function ({ item }) {
                                  return [
                                    _c("div", { staticClass: "name" }, [
                                      _vm._v(_vm._s(item.value)),
                                    ]),
                                    _c("span", { staticClass: "addr" }, [
                                      _vm._v(_vm._s(item.address)),
                                    ]),
                                  ]
                                },
                              },
                            ]),
                            model: {
                              value: _vm.form.postalCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "postalCode", $$v)
                              },
                              expression: "form.postalCode",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "省份", prop: "provinceId" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "320px" },
                            attrs: { maxlength: 2, placeholder: "请输入省州" },
                            model: {
                              value: _vm.form.provinceId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "provinceId", $$v)
                              },
                              expression: "form.provinceId",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "城市", prop: "cityName" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "320px" },
                            attrs: { placeholder: "仅限填写数字和英文字符" },
                            model: {
                              value: _vm.form.cityName,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "cityName", $$v)
                              },
                              expression: "form.cityName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "类型", prop: "scinTypeList" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "320px" },
                              attrs: {
                                multiple: "",
                                placeholder: "请选择类型",
                              },
                              model: {
                                value: _vm.form.scinTypeList,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "scinTypeList", $$v)
                                },
                                expression: "form.scinTypeList",
                              },
                            },
                            _vm._l(_vm.scinTypeListOption, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "联系人", prop: "contact" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "320px" },
                            attrs: { placeholder: "请输入联系人" },
                            model: {
                              value: _vm.form.contact,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "contact", $$v)
                              },
                              expression: "form.contact",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "电话", prop: "phoneNumber" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "320px" },
                            attrs: { placeholder: "请填写电话" },
                            model: {
                              value: _vm.form.phoneNumber,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "phoneNumber", $$v)
                              },
                              expression: "form.phoneNumber",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "邮箱", prop: "email" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "320px" },
                            attrs: { placeholder: "请填写邮箱" },
                            model: {
                              value: _vm.form.email,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "email", $$v)
                              },
                              expression: "form.email",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "税号", prop: "taxNo" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "320px" },
                            attrs: { placeholder: "请填写税号" },
                            model: {
                              value: _vm.form.taxNo,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "taxNo", $$v)
                              },
                              expression: "form.taxNo",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "详细地址一", prop: "address" } },
                        [
                          _c("el-input", {
                            staticStyle: {
                              width: "100%",
                              "margin-top": "-20px",
                            },
                            attrs: {
                              placeholder: "仅限除中文的字符，最多输入35位",
                              maxlength: 35,
                            },
                            model: {
                              value: _vm.form.address,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "address", $$v)
                              },
                              expression: "form.address",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialogfooter",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "div",
            { staticClass: "cancel flex_c_c", on: { click: _vm.cancel } },
            [_vm._v("取 消")]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "ok flex_c_c",
              on: {
                click: function ($event) {
                  return _vm.ok("form")
                },
              },
            },
            [_vm._v(" 确 定 ")]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }