<template>
  <el-dialog
    v-drag
    title="查看订单"
    :visible.sync="dialogFormVisible"
    width="690px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancer"
    center
    :append-to-body="true"
  >
    <BaseTable
      :height="height"
      :tableData="tableData"
      :isPager="false"
      :border="true"
      :loading="false"
    >
    </BaseTable>
  </el-dialog>
</template>
  <script>
const BaseTable = () => import('@/components/table/BaseTable')
import config from '@/utils/config'
  export default {
    components: { BaseTable },
    props: {
      dialogFormVisible: {
        type: Boolean,
        default: false
      },
      orderList: {
          type: Array,
          default: function() {
              return []
          }
      },
      mblId: {
        type: [String, Number]
      }
    },
    data() {
      return {
        config,
        height: 360,
        tableData: {
          fileds: [
            {
              filed: 'referenceNumber',
              label: '参考单号',
              width: '',
              resizable: true
            },
            {
              filed: 'mstTrackingNumber',
              label: '主单号',
              width: '',
              resizable: true
            },
            {
              filed: 'trackingNumber',
              label: '子单号',
              resizable: true
            }
          ],
          columns: [],
          total: 0,
          showIndex: true
        }
      }
    },
    watch: {
      dialogFormVisible(val) {
        if (val) {
          this.tableData.columns = this.orderList
        }
      }
    },
    methods: {
      ok() {
        this.$emit('ok')
      },
      cancer() {
        this.$emit('ok')
      }
    }
  }
  </script>
  <style scoped>
  .tips {
      width: 100%;
      text-align: right;
      height: 16px;
      font-size: 12px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #CDCDCD;
      line-height: 16px;
  }
  </style>

