<template>
  <el-dialog
    v-drag
    title="出仓"
    :visible.sync="dialogVisible"
    width="1200px"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
  >
    <!-- <div v-if="!rowData.firstDepartureDate" class="form-item">
      <label class="lable"><span class="red">* </span>首次出仓时间</label>
      <el-date-picker
        v-model="firstDepartureDate"
        style="width: 100%"
        type="date"
        placeholder="请选择时间"
        value-format="yyyy-MM-dd"
        format="yyyy/MM/dd">
      </el-date-picker>
    </div> -->
    <div class="content">
      <ul class="row">
        <li class="row header">
          <div class="cell1">派送类型</div>
          <div class="cell2">提单件数</div>
          <div class="cell2">已到仓件数</div>
          <div class="cell2">已出仓件数</div>
          <div class="cell2">已出仓板数</div>
          <div class="cell2">本次出仓板数</div>
          <div class="cell2">本次出仓件数</div>
          <div class="cell3">出仓时间</div>
          <div class="cell3">预计送仓时间</div>
          <div class="cell4">出仓备注</div>
        </li>
        <div class="data">
          <li v-for="(item, $index) in dataList" :key="$index" class="row bodyRow">
            <div class="cell1">{{ item.code ? `${item.typeName}-${item.code}` : item.typeName }}</div>
            <div class="cell2">{{ item.qty }}</div>
            <div class="cell2">{{ item.arrivalQty }}</div>
            <div class="cell2">{{ item.departureQty }}</div>
            <div class="cell2">{{ item.departureCbm }}</div>
            <div class="cell2" style="text-indent: 0px">
              <el-input v-model="item.thisTimeDepartureCbm" />
            </div>
            <div class="cell2" style="text-indent: 0px">
              <el-input v-model="item.thisTimeDepartureQty" />
            </div>
            <div class="cell3" style="text-indent: 0px">
              <el-date-picker
                v-model="item.departureDate"
                style="width: 100%"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="请选择日期">
              </el-date-picker>
            </div>
            <div class="cell3" style="text-indent: 0px">
              <el-date-picker
                v-model="item.estimatedDeliveryTime"
                style="width: 100%"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="请选择日期">
              </el-date-picker>
            </div>
            <div class="cell4">
              <el-input v-model="item.departureRemarks" />
            </div>
          </li>
        </div>
      </ul>
    </div>
    <span slot="footer" class="footers">
      <div class="cancel flex_c_c" @click="cancel">取 消</div>
      <div class="ok flex_c_c" @click="ok">确 定</div>
    </span>
  </el-dialog>
</template>
<script>
import { getFacilityDetail, departruedFacility } from '@/api/bill'
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    rowData: {
      type: Object,
      default: () => { return {} }
    }
  },
  data() {
    return {
      arriveList: [],
      dataList: [],
      firstDepartureDate: '' // 首次出仓时间
    }
  },
  watch: {
    async dialogVisible(val) {
      if (val) {
        try {
          const result = await getFacilityDetail({ mblId: this.rowData.id })
          if (result.success) {
            this.dataList = result.data.departuredDetail.map(a => {
              return {
                ...a,
                thisTimeDepartureQty: '',
                thisTimeDepartureCbm: '',
                departureDate: '',
                departureRemarks: '',
                estimatedDeliveryTime: ''
              }
            })
          } else {
            this.$message.error(result.errorMessage)
          }
        } catch (error) {
          console.error(error)
        }
      }
    }
  },
  methods: {
    cancel() {
      this.$emit('cancel')
    },
    async ok() {
      let flag = true
      // if (!this.rowData.firstDepartureDate && !this.firstDepartureDate) {
      //   this.$message.error('首次出仓时间未填写')
      //   return
      // }
      for (let i = 0; i < this.dataList.length; i++) {
        const thisTimeDepartureQty = this.dataList[i].thisTimeDepartureQty
        const thisTimeDepartureCbm = this.dataList[i].thisTimeDepartureCbm
        // const departureDate = this.dataList[i].departureDate
        const total = this.dataList[i].departureQty + +thisTimeDepartureQty
        const total2 = this.dataList[i].arrivalQty - this.dataList[i].departureQty
        if (total > this.dataList[i].qty) {
          this.$message.error(`第${i + 1}行，已出仓件数加本次出仓件数不能超过已到仓件数。`)
          flag = false
          break
        } else if (thisTimeDepartureQty > total2) {
          this.$message.error(`第${i + 1}行，本次出仓件数不能超过已到仓件数减已出仓件数之和。`)
          flag = false
          break
        } else if (thisTimeDepartureCbm === 0 || thisTimeDepartureCbm === '0') {
          this.$message.error(`第${i + 1}行，本次出仓板数需大于0。`)
          flag = false
          break
        }
        // else if (thisTimeDepartureCbm === '' || thisTimeDepartureCbm === '0') {
        //   this.$message.error(`第${i + 1}行，本次出仓板数不能为空或0。`)
        //   flag = false
        //   break
        // } else if (thisTimeDepartureQty === '' || thisTimeDepartureQty === '0') {
        //   this.$message.error(`第${i + 1}行，本次出仓件数不能为空或0。`)
        //   flag = false
        //   break
        // } else if (departureDate === '' || departureDate === null) {
        //   this.$message.error(`第${i + 1}行，出仓时间不能为空。`)
        //   flag = false
        //   break
        // }
      }
      const departruedDetails = this.dataList.filter(a => a.thisTimeDepartureCbm && a.thisTimeDepartureQty && a.departureDate)
      if (flag && departruedDetails.length) {
        try {
          const params = {
            mblId: this.rowData.id,
            // firstDepartureDate: this.firstDepartureDate ? this.firstDepartureDate : undefined,
            departruedDetails
          }
          const result = await departruedFacility(params)
          if (result.success) {
            this.$message.success('操作成功')
            this.$emit('ok')
            // this.firstDepartureDate = ''
          } else {
            this.$message.error(result.errorMessage)
          }
        } catch (error) {
          console.error(error)
        }
      }
    }
  }
}
</script>
<style lang="less" scoped>
/deep/ .el-dialog__body {
  padding: 20px 20px;
}
.footers {
  width: 100%;
  display: flex;
  justify-content: center;
  .cancel {
    width: 120px;
    height: 40px;
    background: #EEEEEE;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    margin-right: 16px;
    cursor: pointer;
  }
  .ok {
    width: 120px;
    height: 40px;
    background: #2395f3;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    cursor: pointer;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
}
.content {
  width: 100%;
  max-height: 320px;
  border: 1px solid #EEEEEE;
  border-radius: 4px;
  overflow: hidden;
  .data {
    width: 100%;
    min-height: 280px;
    max-height: 280px;
    overflow-y: auto;
  }
  .row {
    width: 100%;
    height: auto;
  }
  .bodyRow {
    width: 1160px;
    height: 40px;
    background: #f7f7f7;
    opacity: 1;
    border-bottom: 1px solid #EEEEEE;
    display: flex;
    font-size: 12px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    line-height: 40px;
    text-align: center;
    .cell3 {
      text-align: left;
      text-indent: 15px;
    }
    &:last-child {
      border-bottom: 1px solid #eeeeee;
    }
  }
  .header {
    width: auto;
    height: 40px;
    background: #f7f7f7;
    border-radius: 4px 4px 0px 0px;
    opacity: 1;
    border-bottom: 1px solid #EEEEEE;
    display: flex;
    font-size: 12px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    line-height: 40px;
    text-align: center;
  }
  .cell1 {
    width: 200px;
    border-right: 1px solid #eeeeee;
  }
  .cell2 {
    width: 100px;
    border-right: 1px solid #eeeeee;
  }
  .cell3 {
    width: 160px;
    border-right: 1px solid #eeeeee;
  }
  .cell4 {
    width: 212px;
    border-right: 1px solid #eeeeee;
  }
  .add {
    color: #2395f3;
    cursor: pointer;
  }
  .delete {
    background: #ffffff;
  }
  .icon {
    color: #2395f3;
    font-size: 16px;
  }
  :deep(.el-input__inner) {
    border: 0;
  }
}
.form-item {
  width: 320px;;
  margin-bottom: 15px;
  .lable {
    width: 100%;
    height: 19px;
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    line-height: 20px;
    margin-bottom: 5px;
  }
  .red {
    color: rgba(255, 116, 68, 1);
  }
}
</style>
