<template>
  <el-dialog
    v-drag
    :title="editData.id ? '修改': '新增'"
    :visible.sync="dialogVisible"
    width="520px"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
  >
    <div class="content">
      <el-form ref="form" class="form" :model="form" :rules="rules">
        <el-form-item prop="shipper" label="模板名称">
          <el-input v-model="form.templateName" style="width: 100%;" />
        </el-form-item>
        <el-form-item prop="shipper" label="代理名称">
          <el-input v-model="form.supplierName" style="width: 100%;" />
        </el-form-item>
      </el-form>
      <div class="data">
        <el-table
          :data="tableData"
          :height="240"
          size="medium"
          :header-cell-style="headerCellStyle">
          <el-table-column
            prop="emails"
            label="邮箱"
            align="center"
            width="">
            <template slot-scope="scope">
              <el-input v-model="scope.row.email" style="width: 100%;" placeholder="请输入" />
            </template>
          </el-table-column>
          <el-table-column
            prop="opt"
            align="center"
            width="120">
            <template slot="header">
              <div class="fp-cell" @click.stop="add()">
                <svg-icon className="icon1" iconClass="add"></svg-icon>
                选择
              </div>
            </template>
            <template slot-scope="scope">
              <i
                class="el-icon-delete"
                style="color: #2395f3; cursor: pointer"
                @click="deleteRow(scope.$index)"
              />
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <span slot="footer" class="footers">
      <div class="cancel flex_c_c" @click="cancel">取 消</div>
      <div class="ok flex_c_c" @click="ok('form')">确 定</div>
    </span>
  </el-dialog>
</template>

<script>
import { ChangeDoTemplate } from '@/api/bill'
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    typeId: {
      type: Number,
      require: true
    },
    headerCellStyle: {
      type: Object,
      default: () => {
        return {
          background: '#F7F7F7',
          color: '#333333',
          fontWeight: '400'
        }
      }
    },
    editData: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  data() {
    return {
      tableData: [],
      form: {
        templateName: '',
        supplierName: ''
      },
      rules: {
        templateName: [{ required: true, message: '请选择模板名称', trigger: 'blur' }],
        supplierName: [{ required: true, message: '请选择代理名称', trigger: 'blur' }]
      },
      loading: false
    }
  },
  watch: {
    dialogVisible(val) {
      if (val) {
        if (this.editData.id) {
          this.form = {
            templateName: this.editData.templateName,
            supplierName: this.editData.supplierName
          }
          this.tableData = this.editData.emails.map(a => {
            return {
              email: a
            }
          })
        }
      }
    }
  },
  methods: {
    cancel() {
      this.$emit('cancel')
    },
    ok(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const emails = []
          this.tableData.forEach(a => {
            if (a.email) {
              emails.push(a.email)
            }
          })
          const params = {
            ...this.form,
            emails,
            id: this.editData.id || undefined
          }
          ChangeDoTemplate(params).then((res) => {
            if (res.success) {
              this.$message.success('新增成功')
              this.$store.dispatch('getDoTplList')
              this.$emit('ok')
            } else {
              this.$message.error(res.errorMessage)
            }
          })
        }
      })
    },
    add() {
      this.tableData.push({
        email: '',
        isEdit: true
      })
    },
    deleteRow(index) {
      this.tableData.splice(index, 1)
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  width: 100%;
  height: auto;
  margin: 0 auto;
  .form {
      margin-top: 10px;
      &:deep(.el-form-item__label) {
        line-height: 30px;
        font-size: 14px;
      }
      &:deep(.el-form-item) {
        margin-bottom: 10px;
        position: relative;
      }
      overflow: hidden;
    }
  .data {
    width: 100%;
    margin: 10px auto 0;
    height: 240px;
    &:deep(.el-input__inner) {
      border: 0;
    }
    &:deep(.el-table--medium .el-table__cell) {
      padding: 0;
    }
    &:deep(.el-table .cell) {
      padding: 0;
    }
  }
  .fp-cell {
    color: #2395F3;
    cursor: pointer;
    line-height: 40px;
  }
  .icon1 {
    font-size: 20px;
    vertical-align: -5px;
  }
}
.footers {
  width: 100%;
  display: flex;
  justify-content: center;
  .cancel {
    width: 120px;
    height: 40px;
    background: #eeeeee;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    margin-right: 16px;
    cursor: pointer;
  }
  .ok {
    width: 120px;
    height: 40px;
    background: #2395f3;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    cursor: pointer;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
}
/deep/ .el-dialog__body {
  padding: 0px 20px 40px;
}
/deep/ .el-dialog__header {
  border: 0;
}
</style>
