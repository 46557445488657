<template>
  <el-dialog
    v-drag
    :visible.sync="dialogVisible"
    width="1160px"
    :show-close="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="beforeClose"
    title="推送FMS"
    top="5vh"
  >
    <div v-loading="loading" class="view">
      <div class="top">
        <div class="left">
          <div class="item">
            <p class="lable">派送类型</p>
            <p class="num">{{ pickUpType | pickUpTypeFormatter }}</p>
          </div>
          <div class="item">
            <p class="lable">订单件数</p>
            <p class="num">{{ qty }}</p>
          </div>
          <div class="item">
            <p class="lable">FMS订单件数</p>
            <p class="num">{{ fmsOrderNum }}</p>
          </div>
        </div>
        <div class="right">
          <el-upload
            action="#"
            :show-file-list="false"
            :http-request="httpRequest"
          >
            <div class="upload">
              <img src="@/assets/images/bill/upload-3.png" alt="" />
              上传FBA信息
            </div>
          </el-upload>
          <div class="download" @click="download">
            <img src="@/assets/images/bill/download-1.png" alt="" />
            下载模版
          </div>
        </div>
      </div>
      <div class="middle">
        <p class="title">FMS派送方式</p>
        <BaseTable :isPager="false" :border="true" height="240px" :tableData="tableData" @rowclick="rowclick">
          <template slot="sendWayType" slot-scope="row">
            {{ row.scope.sendWayType | sendWayTypeFormatter }}
          </template>
          <template slot="remark" slot-scope="row">
            <div class="input">
              <el-input v-model="row.scope.remark" placeholder="请输入备注" />
            </div>
          </template>
          <template slot="fbaInfo" slot-scope="row">
            <template v-if="row.scope.fbaInfo.length === 0 && (row.scope.sendWayType === 1 || row.scope.sendWayType === 5)">
              <p class="need-import">需要导入</p>
            </template>
            <template v-else-if="row.scope.fbaInfo.length > 0">
              <!-- TODO 亚马逊仓 -->
              <!-- FBA#数量：{{ row.scope.fbaInfo[0].qty }} -->
              <span>FBA#：{{ row.scope.fbaInfo.length }}</span> &nbsp;&nbsp;
              <span>Qty：{{ row.scope.fbaInfo | formatterNum }}</span>
            </template>
            <template v-else>
              - -
            </template>
          </template>
        </BaseTable>
      </div>
      <div class="middle">
        <p class="title">派送方式订单列表</p>
        <BaseTable :isPager="false" :border="true" height="240px" :tableData="orderLists">
        </BaseTable>
      </div>
    </div>
    <span slot="footer" class="dialogfooter">
      <div class="cancel flex_c_c" @click="cancel">取 消</div>
      <div class="ok flex_c_c" @click="ok('form')">
        推送
      </div>
    </span>
  </el-dialog>
</template>

<script>
import * as XLSX from 'xlsx'
import { getFMSReqeust, pushToFMS } from '@/api/bill'
const BaseTable = () => import('@/components/table/BaseTable')
import config from '@/utils/config'
export default {
  components: {
    BaseTable
  },
  filters: {
    pickUpTypeFormatter(pickUpType) {
      const obj = {
        0: '自提',
        1: '直送',
        2: '回仓'
      }
      return obj[pickUpType]
    },
    sendWayTypeFormatter(sendWayType) {
      return config.FMSMBLSendWayTypeOptions.find((a) => a.value === sendWayType).label
    },
    formatterNum(fbaInfo) {
      let num = 0
      fbaInfo.forEach(a => {
        num += +a.qty
      })
      return num
    }
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    mblId: {
      type: [String, Number],
      default: '449865264325317'
    },
    pickUpType: {
      type: Number,
      default: 0
    },
    qty: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      type: '',
      config,
      tableData: {
        fileds: [
          {
            filed: 'sendWayType',
            label: '派送方式',
            width: '140',
            isCustom: true,
            resizable: true
          },
          {
            filed: 'code',
            label: '代码',
            width: '200',
            resizable: true
          },
          {
            filed: 'qty',
            label: '件数',
            width: '100',
            resizable: true
          },
          {
            filed: 'remark',
            label: '备注',
            width: '',
            isCustom: true,
            resizable: true
          },
          {
            filed: 'fbaInfo',
            label: 'FBA信息',
            width: '150',
            isCustom: true,
            resizable: true
          }
        ],
        columns: [],
        total: 0
      },
      loading: false,
      rowIndex: 0, // 当前派送方式订单
      fmsData: {}
    }
  },
  computed: {
    fmsOrderNum() {
      let num = 0
      this.fmsData.containerItems?.forEach((item) => {
        item.sendWayItems.forEach((a) => {
          a.orderItems.forEach((n) => {
            num += +n.qty
          })
        })
      })
      return num
    },
    orderLists() {
      const columns = this.tableData.columns[this.rowIndex]?.orderItems || []
      return {
        fileds: [
          {
            filed: 'referenceNumber',
            label: '参考单号',
            width: '',
            resizable: true
          },
          {
            filed: 'trackingNumber',
            label: '追踪单号',
            width: '',
            resizable: true
          },
          {
            filed: 'qty',
            label: '件数',
            width: '',
            resizable: true
          }
        ],
        columns,
        total: columns.length
      }
    }
  },
  watch: {
    dialogVisible(val) {
      if (val) {
        this.type = ''
        this.getData()
      }
    }
  },
  // created() {
  //   this.getData()
  // },
  methods: {
    beforeClose() {
      this.$emit('cancel')
    },
    cancel() {
      this.$emit('cancel')
    },
    async getData() {
      try {
        const res = await getFMSReqeust({ mblId: this.mblId })
        if (res.success) {
          this.fmsData = res.data
          const arr = []
          res.data.containerItems?.forEach((item) => {
            item.sendWayItems.forEach((a) => {
              let qty = 0
              a.orderItems.forEach((n) => {
                qty += +n.qty
              })
              arr.push({
                ...a,
                qty
              })
            })
          })
          this.tableData.columns = arr
        } else {
          this.$message.error(res.errorMessage)
          this.$emit('close')
        }
      } catch (error) {
        console.log(error)
      }
    },
    rowclick(row) {
        this.rowIndex = this.tableData.columns.findIndex(a => a === row) || 0
    },
    async ok() {
      const { fmsData } = this
      let flag = true
      fmsData.containerItems.forEach((item, index) => {
        item.sendWayItems.forEach((a, sendWayIndex) => {
          let rowIndex = 0
          for (let i = 0; i < index; i++) {
            rowIndex += fmsData.containerItems[i].sendWayItems.length
          }
          rowIndex += sendWayIndex
          a.remark = this.tableData.columns[rowIndex].remark
          a.fbaInfo = this.tableData.columns[rowIndex].fbaInfo
          if ((a.sendWayType === 1 || a.sendWayType === 5) && a.fbaInfo.length === 0) {
            flag = false
          }
          if (a.fbaInfo.length > 0) {
            a.fbaInfo[0].mblSendWayId = 0
          }
        })
      })
      if (!flag) {
        this.$message.error('有亚马逊仓未上传FBA信息')
        return
      }
      console.log('fmsData', fmsData)
      this.loading = true
      try {
        const res = await pushToFMS(fmsData, this.mblId)
        if (res.success) {
          this.$message.success('推送成功')
          this.$emit('ok')
        } else {
          this.$message.error(res.errorMessage)
        }
        this.loading = false
      } catch (error) {
        console.log(error)
      }
    },
    httpRequest({ file }) {
      // this.fileList = [{
      //   fileName: file.name,
      //   fileSize: Math.ceil(file.size / 1024)
      // }]
      const reader = new FileReader()
        const that = this
        reader.onload = async function(e) {
          let flag = true
          try {
          const data = e.target.result
          const wb = XLSX.read(data, { type: 'binary' })
          const jdata = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]])
            console.log('jdata', jdata)
          jdata
            .forEach((a) => {
              const newKey = Object.keys(a)
              if (newKey.length !== 4) {
                flag = false
                throw new Error()
              }
              //  0 *仓库代码	1 *FBA#	 2 *PO#	 3 *件数
              const code = a[newKey[0]]
              const index = that.tableData.columns.findIndex(a => a.code === code)
              if (index > -1) {
                const data = that.tableData.columns[index]
                data.fbaInfo = [{
                  fbaNo: a[newKey[1]],
                  poNumber: a[newKey[2]],
                  qty: a[newKey[3]]
                }]
                that.$set(that.tableData.columns, index, data)
              }
            })
            that.$message.success('导入成功')
          } catch (error) {
            if (!flag) {
              that.$message.error('模板不正确')
            }
            console.error(error)
          }
        }
        reader.readAsBinaryString(file)
    },
    download() {
      const elink = document.createElement('a')
      elink.style.display = 'none'
      elink.href = `/backEnd/api/${config.supplyCode}/File/downLoadTemplate?guid=9f2e22966cb2426cbd7a473e7637ac11`
      document.body.appendChild(elink)
      elink.click()
      URL.revokeObjectURL(elink.href) // 释放URL 对象
      document.body.removeChild(elink)
    }
  }
}
</script>

<style scoped lang="less">
/deep/ .el-dialog__body {
  padding: 0 40px 40px;
}
.view {
  position: relative;
  width: 100%;
  height: auto;
  .top {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    .left {
      display: flex;
    }
    .item {
      width: 160px;
      height: 70px;
      .lable {
        width: 100%;
        height: 30px;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        line-height: 30px;
      }
      .num {
        width: 100%;
        height: 40px;
        background: #f7f7f7;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        border: 1px solid #eeeeee;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        line-height: 40px;
        text-indent: 10px;
      }
      & + .item {
        margin-left: 20px;
      }
    }
    .right {
      display: flex;
      padding-top: 30px;
      .upload {
        width: 140px;
        height: 40px;
        background: #35ba83;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        line-height: 40px;
        cursor: pointer;
        text-align: center;
        img {
          margin-top: 10px;
        }
      }
      .download {
        width: 120px;
        height: 40px;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        border: 1px solid #35ba83;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #35ba83;
        line-height: 40px;
        cursor: pointer;
        text-align: center;
        margin-left: 10px;
        img {
          margin-top: 10px;
        }
      }
    }
  }
  .middle {
    margin-top: 10px;
    height: 270px;
    width: 100%;
    .title {
      width: 100%;
      height: 30px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      line-height: 30px;
    }
    .input {
      width: 100%;
      height: 40px;
      &:deep(.el-input__inner) {
        border: 0;
      }
    }
    .need-import {
      width: 100%;
      text-align: center;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #fe453d;
      line-height: 40px;
    }
    /deep/ .el-table--border .el-table__cell:first-child .cell {
      padding: 0;
    }
    /deep/ .el-table tr td {
      height: 40px;
      padding: 0;
    }
    /deep/ .el-table tr td .cell {
      padding: 0;
    }
  }
}
.dialogfooter {
  display: flex;
  justify-content: center;
  .cancel {
    width: 120px;
    height: 40px;
    background: #eeeeee;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    margin-right: 20px;
    cursor: pointer;
  }
  .ok {
    width: 120px;
    height: 40px;
    background: #2395f3;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    cursor: pointer;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
}
</style>
