<template>
  <el-dialog
    v-drag
    title="添加GGT抬头信息"
    :visible.sync="dialogVisible"
    :append-to-body="true"
    width="800px"
    :show-close="true"
    top="5vh"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
  >
    <div class="dialog-content">
      <el-form ref="form" :model="form" :rules="rules" label-position="top">
        <el-row>
          <el-col :span="24">
            <el-form-item label="公司名称" prop="name">
              <el-input
                v-model="form.name"
                style="width: 100%"
                placeholder="请输入公司名称"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="国家" prop="countryId">
              <el-select
                v-model="form.countryId"
                style="width: 320px"
                placeholder="请选择国家"
              >
                <el-option
                  v-for="item in addressDropCodeOption"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="postalCode" label="邮编">
              <el-autocomplete
                v-model="form.postalCode"
                style="width: 320px"
                placeholder="仅限填写数字和英文字符，最多12位"
                :maxlength="12"
                :fetch-suggestions="querySearchAsync"
                :trigger-on-focus="false"
                @select="handleSelect"
              >
                <template slot-scope="{ item }">
                  <div class="name">{{ item.value }}</div>
                  <span class="addr">{{ item.address }}</span>
                </template>
              </el-autocomplete>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="省份" prop="provinceId">
              <el-input
                v-model="form.provinceId"
                style="width: 320px"
                :maxlength="2"
                placeholder="请输入省州"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="城市" prop="cityName">
              <el-input
                v-model="form.cityName"
                style="width: 320px"
                placeholder="仅限填写数字和英文字符"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="类型" prop="scinTypeList">
              <el-select
                v-model="form.scinTypeList"
                multiple
                style="width: 320px"
                placeholder="请选择类型"
              >
                <el-option
                  v-for="item in scinTypeListOption"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系人" prop="contact">
              <el-input
                v-model="form.contact"
                style="width: 320px"
                placeholder="请输入联系人"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="电话" prop="phoneNumber">
              <el-input
                v-model="form.phoneNumber"
                style="width: 320px"
                placeholder="请填写电话"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="邮箱" prop="email">
              <el-input
                v-model="form.email"
                style="width: 320px"
                placeholder="请填写邮箱"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="税号" prop="taxNo">
              <el-input
                v-model="form.taxNo"
                style="width: 320px"
                placeholder="请填写税号"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="详细地址一" prop="address">
              <el-input
                v-model="form.address"
                style="width: 100%; margin-top: -20px"
                placeholder="仅限除中文的字符，最多输入35位"
                :maxlength="35"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <span slot="footer" class="dialogfooter">
      <div class="cancel flex_c_c" @click="cancel">取 消</div>
      <div v-loading="loading" class="ok flex_c_c" @click="ok('form')">
        确 定
      </div>
    </span>
  </el-dialog>
</template>
<script>
import { GGTChangeSCIN } from '@/api/bill'
import { getAddressFill } from '@/api/basic'
import { isValidEmail } from '@/utils/validate'
import { mapGetters } from 'vuex'
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    mblId: {
      type: String,
      default: ''
    }
  },
  data() {
    const validatePhone = (rule, value, callback) => {
      if (value.length < 10) {
        return callback(new Error('电话格式不正确'))
      } else {
        callback()
      }
    }
    const validateEmail = (rule, value, callback) => {
      if (!value) {
        callback()
      } else if (!isValidEmail(value)) {
        callback(new Error('请输入正确的邮箱'))
      } else {
        callback()
      }
    }
    return {
      options: [],
      form: {
        name: '',
        taxNo: '',
        contact: '',
        email: '',
        phoneNumber: '',
        postalCode: '',
        address: '',
        scinTypeList: '',
        countryId: '',
        provinceId: '',
        cityName: ''
      },
      loading: false,
      rules: {
        address: [
          { required: true, message: '请输入地址', trigger: 'blur' }
        ],
        phoneNumber: [
          { required: true, message: '请填写联系电话', trigger: 'blur' },
          { validator: validatePhone }
        ],
        email: [{ validator: validateEmail }],
        postalCode: [
          { required: true, message: '请填写邮编', trigger: 'blur' }
        ],
        cityName: [{ required: true, message: '请输入城市', trigger: 'change' }],
        countryId: [
          { required: true, message: '请选择国家', trigger: 'change' }
        ],
        provinceId: [{ required: true, message: '请输入省份', trigger: 'change' }],
        // taxNumber: [{ required: true, message: '请输入税号', trigger: 'blur' }],
        name: [
          { required: true, message: '请输入公司名称', trigger: 'blur' }
        ],
        scinTypeList: [{ required: true, message: '请输入类型', trigger: 'change' }],
        contact: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
        taxNo: [{ required: true, message: '请输入税号', trigger: 'blur' }]
      },
      scinTypeListOption: [{
        value: 0,
        label: 'shipper'
      }, {
        value: 1,
        label: 'consignee'
      }, {
        value: 2,
        label: 'importer'
      }, {
        value: 3,
        label: 'notifyParty'
      }]
    }
  },
  computed: {
    ...mapGetters(['addressDropOption']),
    addressDropCodeOption() {
      return this.addressDropOption.map((item) => {
        return {
          value: item.value,
          label: `${item.code} - ${item.label}`
        }
      })
    }
  },
  watch: {
    dialogVisible(val) {
      if (val) {
        this.$refs['form']?.resetFields()
          this.form = {
            name: '',
            taxNo: '',
            contact: '',
            email: '',
            phoneNumber: '',
            postalCode: '',
            address: '',
            scinTypeList: '',
            countryId: '',
            provinceId: '',
            cityName: ''
          }
        }
    }
  },
  mounted() {
    if (!this.addressDropOption || this.addressDropOption.length === 0) {
      this.$store.dispatch('getAddress')
    }
  },
  methods: {
    cancel() {
      this.$emit('cancel')
    },
    ok(formName) {
      const country = this.addressDropOption.find(a => a.value === this.form.countryId)
      const province = country.children.find(a => a.code === this.form.provinceId).value
      const params = {
        ...this.form,
        provinceId: province,
        mblId: this.mblId
      }
      this.$refs[formName].validate(async(valid) => {
        if (valid) {
          this.loading = true
          GGTChangeSCIN(params).then((res) => {
            if (res.success) {
              this.$message.success(res.errorMessage)
              this.$emit('ok')
              this.loading = false
            } else {
              this.$message.error(res.errorMessage)
              this.loading = false
            }
          })
        } else {
          return false
        }
      })
    },
    async querySearchAsync(queryString, cb) {
      if (!this.form.countryId) {
        this.$message.error('请先选择国家')
        return []
      }
      try {
        const res = await getAddressFill({
          countryCode: this.addressDropOption.find(a => a.value === this.form.countryId).code,
          postalCode: queryString
        })
        if (res.success) {
          const arr = res.data.map((item) => {
            return {
              value: item.postalCode,
              address: `${item.cityName}, ${item.provinceCode},${item.postalCode}`
            }
          })
          cb(arr)
          console.log(res)
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.log(error)
      }
    },
    handleSelect(item) {
      const city = item.address.split(',')[0]
      const state = item.address.split(',')[1].trim()
      this.form.provinceId = state
      this.form.cityName = city
    }
  }
}
</script>
<style lang="less" scoped>
.dialog-content {
  width: calc(100% - 88px);
  margin: 0 auto;
}
.dialogfooter {
  display: flex;
  justify-content: center;
  .cancel {
    width: 120px;
    height: 40px;
    background: #EEEEEE;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    margin-right: 20px;
    cursor: pointer;
  }
  .ok {
    width: 120px;
    height: 40px;
    background: #2395f3;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    cursor: pointer;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
}
/deep/ .el-form--label-top .el-form-item__label {
  padding: 0 0 0 0;
}
/deep/ .el-form-item {
  margin-bottom: 10px;
}
/deep/ .el-form-item__label {
  line-height: 30px !important;
}
/deep/ .el-col:nth-child(2n) {
  display: flex;
  justify-content: flex-end;
}
.label-title {
  span {
    color: red;
    font-size: 12px;
  }
}
.taxNum .el-form-item__label {
  display: flex;
}
.form-label {
  display: flex;
  align-items: center;
}
</style>
