<template>
  <el-dialog
    v-drag
    :visible.sync="dialogVisible"
    width="1108px"
    :show-close="true"
    title="选择订单"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="beforeClose"
    append-to-body
  >
    <div class="container">
      <div class="search">
        <el-popover
          ref="popover"
          placement="bottom"
          class="searchPop"
          title="批量搜索"
          trigger="click"
          width="378"
        >
          <div class="close" @click="closeSearchPanel">
            <i class="el-icon-close"></i>
          </div>
          <base-muti-input
            ref="baseMutiInput"
            v-model="referenceNumber"
          ></base-muti-input>
          <div style="text-align: right; margin: 16px 0 0">
            <el-button size="small" @click="handleEmpty">清空</el-button>
            <el-button
              size="small"
              type="primary"
              @click="handleConfirm"
            >确定</el-button
            >
          </div>
        </el-popover>
        <el-input
          :value="text"
          placeholder="请输入提单号搜索"
          class="input-search"
          :disabled="loading"
          @focus="handleFocus"
        ></el-input>
        <div class="btn-search">
          <i class="el-icon-search"></i>
        </div>
      </div>
      <SelectOrderList ref="SelectOrderList" v-model="loading" :rowData="rowData" style="margin-top: 10px;" size="small" @selectItems="selectItems"></SelectOrderList>
    </div>
    <span slot="footer" class="footer">
      <div class="num">
        <el-checkbox v-model="isAllCheck" @change="handleCheckAllChange()"></el-checkbox>
        <span class="all">全选</span>
        <span class="sum">已选择 <span style="color: rgba(35, 149, 243, 1)">{{ selectItemsNum }} </span> 项</span>
      </div>
      <div class="btn cancel" @click="cancel">取消</div>
      <div class="btn ok" @click="ok">确定</div>
    </span>
  </el-dialog>
</template>
<script>
import SelectOrderList from './SelectOrderList'
const BaseMutiInput = () => import('@/components/base/BaseMutiInput')
import { treeToList } from '@/utils/common'
import { onLoadMBL } from '@/api/bill'
  export default {
    components: { SelectOrderList, BaseMutiInput },
    props: {
      dialogVisible: {
        type: Boolean,
        default: false
      },
      selectedArr: {
        type: Array,
        default: function() {
          return []
        }
      },
      rowData: {
        type: Object,
        default: () => {
          return {
            blNo: 'MATS123124'
          }
        }
      }
    },
    data() {
      return {
        isAllCheck: false,
        selectArr: [],
        text: '',
        referenceNumber: '',
        loading: false
      }
    },
    computed: {
      selectItemsNum() {
        let num = 0
        this.selectArr.forEach(item => {
          if (item.children.length > 0) {
            num += item.children.length
          } else {
            num += 1
          }
        })
        return num
      }
    },
    watch: {
      dialogVisible(val) {
        if (val) {
          this.$refs.SelectOrderList?.unCheckAll()
          if (this.selectedArr.length) {
            const arr = treeToList(this.selectedArr)
            this.$refs.SelectOrderList?.filterOrderBySelected(arr)
          }
        }
      }
    },
    methods: {
      cancel() {
        this.$emit('cancel')
      },
     async ok() {
        if (!this.selectItemsNum) {
          this.$message.warning('未选择订单')
          return
        }
          try {
          const params = {
            mblId: this.rowData?.id,
            operation: 0,
            orderItems: []
          }
          this.selectArr.forEach(item => {
              item.children.forEach(a => {
                params.orderItems.push({
                      ...a,
                      sendWay: item.sendWay,
                      sendWayName: item.sendWayName
                  })
              })
          })
          const res = await onLoadMBL(params)
          if (res.success) {
              this.$message.success('绑定成功')
              this.$emit('ok')
          } else {
              this.$message.error(res.errorMessage)
          }
          console.log(res)
        } catch (error) {
          console.log(error)
        }
      },
      beforeClose() {
        this.cancel()
      },
      handleCheckAllChange() {
        if (this.isAllCheck) {
           this.$refs.SelectOrderList.checkAll()
        } else {
          this.$refs.SelectOrderList.unCheckAll()
        }
      },
      selectItems(selectArr) {
        this.selectArr = selectArr
      },
      search() {
        this.$refs.SelectOrderList.unCheckAll()
        this.$refs.SelectOrderList.filterOrder(this.text.split(';'))
      },
      handleFocus() {
        this.$refs.popover.doShow()
      },
      closeSearchPanel() {
        this.$refs.popover.doClose()
      },
      handleConfirm() {
        if (this.referenceNumber && this.referenceNumber !== '') {
          this.text = this.referenceNumber.join(';')
          this.search()
          this.$refs.popover.doClose()
        }
      },
      handleEmpty() {
        this.referenceNumber = ''
        this.text = ''
        this.$refs.baseMutiInput.reset()
        this.$refs.popover.doClose()
        this.$refs.SelectOrderList.filterOrder('')
      }
    }
  }
  </script>

  <style lang="less" scoped>
  .search {
    position: relative;
    display: flex;
    width: 200px;
    margin-right: 5px;
    .searchPop {
      /deep/ .el-popover {
        z-index: 9999;
        right: 0;
      }
      .close {
        position: absolute;
        top: 6px;
        right: 10px;
        cursor: pointer;
        font-size: 20px;
      }
    }
    .input-search {
      width: 166px;
      height: 30px !important;
      /deep/ .el-input__inner {
        height: 30px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
    .btn-search {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 33px;
      height: 30px;
      border: 1px solid #dbe4f2;
      border-left: 0;
      border-radius: 0 4px 4px 0;
      cursor: pointer;
      box-sizing: border-box;
    }
  }
  .footer {
    width: 100%;
    height: 80px;
    background: #FFFFFF;
    box-shadow: 0px 0px 6px 1px rgba(0,0,0,0.16);
    border-radius: 0px 0px 6px 6px;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .num {
        position: absolute;
        left: 50px;
        .all {
            margin-left: 8px;
            font-size: 14px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #B8C3DE;
            line-height: 24px;
        }
        .sum {
            margin-left: 24px;
            font-size: 14px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #B8C3DE;
            line-height: 24px;
        }
      }
  }
  .container {
    padding: 0 0px;
    box-sizing: border-box;
  }
  .btn {
    line-height: 38px;
    text-align: center;
    cursor: pointer;
    width: 120px;
    height: 38px;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
  }
  .cancel {
    border: 1px solid #DBE4F2;
    color: #7C8DB5;
    background: #F2F7FF;
    margin-right: 16px;
  }
  .ok {
    border: 1px solid #2395F3;
    color: #FFFFFF;
    background: #2395F3;
  }
  :deep(.el-dialog__footer) {
    padding: 0;
  }
  </style>

