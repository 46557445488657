var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Drawer",
    {
      attrs: { drawer: _vm.drawer, customTitle: "", size: 700 },
      on: { close: _vm.close },
    },
    [
      _c(
        "div",
        { staticClass: "title", attrs: { slot: "title" }, slot: "title" },
        [
          _c("span", [_vm._v("对外系统提单确认-GGT")]),
          _c("div", { staticClass: "billNo" }, [
            _vm._v("提单号：" + _vm._s(_vm.billNo)),
          ]),
        ]
      ),
      _c(
        "div",
        { staticClass: "view" },
        [
          _c(
            "div",
            { staticClass: "data" },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  staticClass: "form",
                  attrs: { model: _vm.form, rules: _vm.rules },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "shipperSCINId", label: "Shipper" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            filterable: "",
                            placeholder: "请选择Shipper",
                          },
                          model: {
                            value: _vm.form.shipperSCINId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "shipperSCINId", $$v)
                            },
                            expression: "form.shipperSCINId",
                          },
                        },
                        _vm._l(_vm.shipperOptions, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "importerSCINId", label: "Importer" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            filterable: "",
                            placeholder: "请选择Importer",
                          },
                          model: {
                            value: _vm.form.importerSCINId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "importerSCINId", $$v)
                            },
                            expression: "form.importerSCINId",
                          },
                        },
                        _vm._l(_vm.importerOptions, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "consigneeSCINId", label: "Consignee" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            filterable: "",
                            placeholder: "请选择Consignee",
                          },
                          model: {
                            value: _vm.form.consigneeSCINId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "consigneeSCINId", $$v)
                            },
                            expression: "form.consigneeSCINId",
                          },
                        },
                        _vm._l(_vm.consigneeOptions, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "notifyPartySCINId",
                        label: "Notify Party",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { filterable: "", placeholder: "请选择客户" },
                          model: {
                            value: _vm.form.notifyPartySCINId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "notifyPartySCINId", $$v)
                            },
                            expression: "form.notifyPartySCINId",
                          },
                        },
                        _vm._l(_vm.notifyPartyOptions, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "manufactureSCINId",
                        label: "manufacturer",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            filterable: "",
                            placeholder: "请选择制造商",
                          },
                          model: {
                            value: _vm.form.manufactureSCINId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "manufactureSCINId", $$v)
                            },
                            expression: "form.manufactureSCINId",
                          },
                        },
                        _vm._l(_vm.manufactureOptions, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c("el-row", [
                    _c(
                      "div",
                      {
                        staticClass: "add",
                        on: {
                          click: function ($event) {
                            _vm.dialogVisible1 = true
                          },
                        },
                      },
                      [_vm._v("添加GGT抬头信息")]
                    ),
                  ]),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        prop: "doSupplierTemplateId",
                        label: "接收DO代理模板",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            filterable: "",
                            placeholder: "请选择接收DO代理模板",
                          },
                          on: { change: _vm.doChange },
                          model: {
                            value: _vm.form.doSupplierTemplateId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "doSupplierTemplateId", $$v)
                            },
                            expression: "form.doSupplierTemplateId",
                          },
                        },
                        _vm._l(_vm.doOptions, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "tpl-opt",
                          on: {
                            click: function ($event) {
                              _vm.dialogVisible = true
                            },
                          },
                        },
                        [_vm._v("模板管理")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "shipper", label: "接收DO公司名称" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "100%" },
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.doObj.supplierName,
                          callback: function ($$v) {
                            _vm.$set(_vm.doObj, "supplierName", $$v)
                          },
                          expression: "doObj.supplierName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "shipper", label: "接收DO公司邮箱" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "100%" },
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.doObj.emails,
                          callback: function ($$v) {
                            _vm.$set(_vm.doObj, "emails", $$v)
                          },
                          expression: "doObj.emails",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("el-form-item", { attrs: { label: "提单文件" } }, [
                            _c("div", { staticClass: "file" }, [
                              _c("div", { staticClass: "file-name" }, [
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/images/bill/image.png"),
                                  },
                                }),
                                _c("div", { staticClass: "file-des" }, [
                                  _c(
                                    "p",
                                    {
                                      directives: [
                                        {
                                          name: "tooltip",
                                          rawName: "v-tooltip",
                                          value: 184,
                                          expression: "184",
                                        },
                                      ],
                                      staticClass: "name",
                                    },
                                    [_vm._v(_vm._s(_vm.fileList[0]?.fileName))]
                                  ),
                                ]),
                              ]),
                              _c("div", { staticClass: "opt" }, [
                                _c(
                                  "span",
                                  {
                                    staticClass: "review",
                                    on: {
                                      click: function ($event) {
                                        return _vm.check(_vm.fileList[0])
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: require("@/assets/images/bill/review.png"),
                                      },
                                    }),
                                    _vm._v(" 预览 "),
                                  ]
                                ),
                              ]),
                            ]),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("el-form-item", { attrs: { label: "提单发票" } }, [
                            _c("div", { staticClass: "file" }, [
                              _c("div", { staticClass: "file-name" }, [
                                _c("img", {
                                  attrs: {
                                    src: require("@/assets/images/bill/pdf.png"),
                                  },
                                }),
                                _c("div", { staticClass: "file-des" }, [
                                  _c(
                                    "p",
                                    {
                                      directives: [
                                        {
                                          name: "tooltip",
                                          rawName: "v-tooltip",
                                          value: 184,
                                          expression: "184",
                                        },
                                      ],
                                      staticClass: "name",
                                    },
                                    [_vm._v(_vm._s(_vm.fileList[1]?.fileName))]
                                  ),
                                ]),
                              ]),
                              _c("div", { staticClass: "opt" }, [
                                _c("span", { staticClass: "no-review" }, [
                                  _vm._v(" 不可预览 "),
                                ]),
                              ]),
                            ]),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "footers" }, [
            _c(
              "div",
              { staticClass: "cancel flex_c_c", on: { click: _vm.close } },
              [_vm._v("取 消")]
            ),
            _c(
              "div",
              {
                staticClass: "ok flex_c_c",
                on: {
                  click: function ($event) {
                    return _vm.ok("form")
                  },
                },
              },
              [_vm._v("确 定")]
            ),
          ]),
          _c("DoTplDialog", {
            attrs: { dialogVisible: _vm.dialogVisible },
            on: {
              cancel: function ($event) {
                _vm.dialogVisible = false
              },
            },
          }),
          _c("SicnAddDialog", {
            attrs: { dialogVisible: _vm.dialogVisible1, mblId: _vm.mblId },
            on: {
              cancel: function ($event) {
                _vm.dialogVisible1 = false
              },
              ok: _vm.ok1,
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }