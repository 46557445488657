var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading",
        },
      ],
      staticClass: "table",
    },
    [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "tbody" },
        _vm._l(_vm.dataList, function (item, $index) {
          return _c("div", { key: $index, staticClass: "row" }, [
            _c("div", { staticClass: "top" }, [
              _c(
                "div",
                { staticClass: "cell" },
                [
                  _c("el-checkbox", {
                    staticClass: "checkBox",
                    attrs: { indeterminate: item.isIndeterminate },
                    on: {
                      change: function ($event) {
                        return _vm.handleCheckAllChange($index)
                      },
                    },
                    model: {
                      value: item.isCheck,
                      callback: function ($$v) {
                        _vm.$set(item, "isCheck", $$v)
                      },
                      expression: "item.isCheck",
                    },
                  }),
                  _c("p", [_vm._v(_vm._s(item.referenceNumber))]),
                ],
                1
              ),
              _c("div", { staticClass: "cell" }, [
                _c("p", [_vm._v(_vm._s(item.mstTrackingNumber))]),
              ]),
              _c("div", { staticClass: "cell" }, [
                _c("p", [_vm._v(_vm._s(item.children.length || 1))]),
              ]),
              _c("div", { staticClass: "cell" }, [
                _c("p", [_vm._v(_vm._s(item.uploadTime))]),
              ]),
              _c(
                "div",
                { staticClass: "cell" },
                [
                  item.children.length > 1
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: {
                            click: function ($event) {
                              return _vm.open($index, item.isCollapse)
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(item.isCollapse ? "收起" : "展开") +
                              " "
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]),
            item.isCollapse
              ? _c("div", { staticClass: "content" }, [
                  _c("div", { staticClass: "title" }, [_vm._v("选择子单号")]),
                  _c(
                    "div",
                    {
                      staticClass: "order-items",
                      style: {
                        width:
                          _vm.size === "small" ? "100%" : "calc(100% - 200px)",
                      },
                    },
                    _vm._l(item.children, function (order) {
                      return _c(
                        "div",
                        { key: order.trackingNumber, staticClass: "order" },
                        [
                          _c("el-checkbox", {
                            on: {
                              change: function ($event) {
                                return _vm.handleCheckOrderChange($index)
                              },
                            },
                            model: {
                              value: order.isCheck,
                              callback: function ($$v) {
                                _vm.$set(order, "isCheck", $$v)
                              },
                              expression: "order.isCheck",
                            },
                          }),
                          _c(
                            "span",
                            {
                              staticClass: "lable",
                              class: [
                                {
                                  checked: order.isCheck,
                                  uncheck: !order.isCheck,
                                },
                              ],
                            },
                            [_vm._v(" " + _vm._s(order.trackingNumber) + " ")]
                          ),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                ])
              : _vm._e(),
          ])
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "header" }, [
      _c("div", { staticClass: "cell", staticStyle: { width: "30%" } }, [
        _vm._v("客户单号"),
      ]),
      _c("div", { staticClass: "cell" }, [_vm._v("主单号")]),
      _c("div", { staticClass: "cell" }, [_vm._v("件数")]),
      _c("div", { staticClass: "cell" }, [_vm._v("上传时间")]),
      _c("div", { staticClass: "cell", staticStyle: { width: "10%" } }, [
        _vm._v("操作"),
      ]),
      _c("div", { staticClass: "scoll" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }