<template>
  <el-dialog
    v-drag
    title="HOLD"
    :visible.sync="dialogVisible"
    width="880px"
    :show-close="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="beforeClose"
  >
    <div class="container">
      <div class="top">
        <div class="tab-content">
          <div class="step-content flex_c_c" style="100%">
            <div :class="{ active: active > 0 }">
              <svg-icon className="icon" iconClass="one"></svg-icon>
              输入主单号
            </div>
            <svg-icon
              className="icon arrow"
              :class="{ active: active > 1 }"
              iconClass="arrow"
            ></svg-icon>
            <div :class="{ active: active > 1 }">
              <svg-icon className="icon" iconClass="two"></svg-icon>
              选择HOLD类型
            </div>
            <svg-icon
              className="icon arrow"
              :class="{ active: active > 2 }"
              iconClass="arrow"
            ></svg-icon>
            <div :class="{ active: active > 2 }">
              <svg-icon className="icon" iconClass="three"></svg-icon>
              选择HOLD原因
            </div>
          </div>
        </div>
      </div>
      <div class="header">
        <el-input
          v-if="active === 1"
          v-model="billNo"
          placeholder="请输入内容"
          style="width: 586px; margin-top: 32px"
          class="input-search"
          clearable
          @change="result = false"
        >
          <el-button
            slot="append"
            class="searchbtn"
            icon="el-icon-search"
            @click="search"
          >查询</el-button
          >
        </el-input>
        <div v-if="active === 2" class="holdType">
          <div>
            主单号：<span class="holdNo">{{ billNo }}&nbsp; </span>
            选择HOLD类型：
          </div>
          <div class="type">
            <div
              :class="['hodeType-item', hodeActive === 0 ? 'hodeActive' : '']"
              @click="handleSelectHode(0)"
            >
              主单
              <img
                v-if="hodeActive === 0"
                src="@/assets/images/gou.png"
                alt=""
              />
            </div>
            <div
              :class="['hodeType-item', hodeActive === 1 ? 'hodeActive' : '']"
              @click="handleSelectHode(1)"
            >
              分单
              <img
                v-if="hodeActive === 1"
                src="@/assets/images/gou.png"
                alt=""
              />
            </div>
            <div
              :class="['hodeType-item', hodeActive === 2 ? 'hodeActive' : '']"
              @click="handleSelectHode(2)"
            >
              柜子
              <img
                v-if="hodeActive === 2"
                src="@/assets/images/gou.png"
                alt=""
              />
            </div>
            <!-- <div
              :class="['hodeType-item', hodeActive === 3 ? 'hodeActive' : '']"
              @click="handleSelectHode(3)"
            >
              订单
              <img
                v-if="hodeActive === 3"
                src="@/assets/images/gou.png"
                alt=""
              />
            </div> -->
          </div>
        </div>
        <div v-if="active === 3"></div>
      </div>
      <div class="data">
        <img
          v-if="!result"
          class="empty"
          src="@/assets/images/bill/empty.png"
          alt=""
        />
        <div v-else class="result">
          <template v-if="!state">
            <p>
              主单号：<span class="billNo">{{ billNo }}</span> 查询结果
            </p>
            <p>
              此提单处于
              <span class="status">{{ billData[0] | filterStatus }}</span>
              状态，不支持HOLD
            </p>
          </template>
          <template v-if="hodeActive === 1 && active === 2">
            <div class="filter">
              <span>订单列表：</span>
              <el-input class="bill-search">
                <el-button slot="append" icon="el-icon-search"></el-button>
              </el-input>
            </div>
            <BaseTable
              class="baseTable"
              :isPager="false"
              :border="true"
              :height="200"
              :tableData="tableData"
              @handleSelectionChange="handleSelectionChange"
            ></BaseTable>
            <p v-if="selectionItem.length > 0" class="pager">已选  <span class="pagerNum">{{ selectionItem.length }}</span>  项</p>
          </template>
          <template v-if="active === 3">
            <el-form ref="form" class="form" :model="form" :rules="rules" label-position="top">
              <el-row>
                <el-col :span="24">
                  <el-form-item label="选择HOLD原因" prop="reason">
                    <el-select
                      v-model="form.reason"
                      style="width: 100%"
                      placeholder="请选择选择HOLD原因"
                    >
                      <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col v-if="form.reason === '其他'" :span="24">
                  <el-form-item label="填写详细描述" prop="reason1">
                    <el-input
                      v-model="form.reason1"
                      type="textarea"
                      style="width: 100%"
                      :rows="4"
                      placeholder="请输入详细描述内容"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </template>
        </div>
      </div>
    </div>
    <span slot="footer" class="footers">
      <template v-if="result">
        <div class="cancel flex_c_c" @click="cancel">
          {{ state ? "上一步" : "关闭" }}
        </div>
        <div v-if="state" class="ok flex_c_c" @click="ok">下一步</div>
      </template>
    </span>
  </el-dialog>
</template>

<script>
import { MBLHoldDetail, getHoldReasonDropdown, holdMBL } from '@/api/bill'
import config from '@/utils/config'
const BaseTable = () => import('@/components/table/BaseTable')

export default {
  filters: {
    filterStatus(row) {
      console.log('config.billStatusOptions', config.billStatusOptions, row)
      const status = config.billStatusOptions.find(
        (a) => a.value === row.status
      )
      const subStatus = config.billShipSubtabOptions[status.value].find(
        (a) => a.value === row.subStatus
      )
      return subStatus.label
    }
  },
  components: { BaseTable },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    mblId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      active: 1,
      result: false,
      state: true,
      billNo: '',
      hodeActive: 0,
      billData: [],
      selectionItem: [],
      form: {
        reason: '',
        reason1: ''
      },
      rules: {
        reason: [
          { required: true, message: '请选择原因', trigger: 'change' }
        ],
        reason1: [
          { required: true, message: '请输入原因', trigger: 'blur' }
        ]
      },
      options: []
    }
  },
  computed: {
    tableData() {
      return {
        fileds: [
          {
            filed: 'mblNo',
            label: '主单号',
            width: '',
            resizable: true
          },
          {
            filed: 'blNo',
            label: '提单号',
            width: '',
            resizable: true
          },
          {
            filed: 'containerNumber',
            label: '柜号',
            width: '',
            resizable: true
          },
          {
            filed: 'pickUpType',
            label: '提货方式',
            width: '',
            formatter: row => {
              return config.pickUpTypeOptions.find(a => a.value === row.pickUpType).label
            },
            resizable: true
          }
        ],
        mutiCheck: true,
        columns: this.billData.filter((a) => a.isHold === true),
        total: 0
      }
    }
  },
  watch: {
    dialogVisible(val) {
      if (val) {
        this.state = true
        this.result = false
        this.billNo = ''
        this.billData = []
        this.selectionItem = []
        this.hodeActive = 0
        this.active = 1
      }
    }
  },
  methods: {
     async getOptions() {
      try {
        const res = await getHoldReasonDropdown({
          type: this.hodeActive
        })
        if (res.success) {
          this.options = [...res.data, {
            value: '其他',
            label: '其他'
          }]
        }
      } catch (error) {
        console.log(error)
      }
     },
    cancel() {
      if (this.state) {
        this.active === 1 && this.$emit('cancel')
        this.active = this.active - 1
        if (this.active !== 1) {
          this.hodeActive = 0
        } else {
          this.result = false
        }
      } else {
        this.$emit('cancel')
      }
    },
    close() {
      this.$emit('cancel')
    },
    async ok() {
      if (this.active !== 3) {
        if (this.active === 2 && this.hodeActive === 1) {
          if (this.selectionItem.length === 0) {
            this.$message.warning('请勾选分单')
            return
          }
        }
        if (this.active === 2) {
          this.getOptions()
        }
        this.active = this.active + 1
      } else {
        if ((this.form.reason === '其他' && !this.form.reason1) || !this.form.reason) {
          this.$message.warning('请选择HOLD原因')
          return
        }
        try {
          const blIds = this.selectionItem.map(a => {
            return a.id
          })
          const res = await holdMBL({
            blIds: blIds.length > 0 ? blIds : undefined,
            reason: this.form.reason1 || this.form.reason,
            type: this.hodeActive,
            mblId: this.billData[0].id
          })
          console.log(res)
          if (res.success) {
            this.$emit('cancel')
            this.$message.success('HOLD成功')
          } else {
            this.$message.error(res.errorMessage)
          }
        } catch (error) {
          console.log(error)
        }
      }
    },
    beforeClose() {
      this.$emit('cancel')
    },
    handleSelectHode(index) {
      this.hodeActive = index
    },
    async search() {
      if (!this.billNo) return
      try {
        const res = await MBLHoldDetail({
          mblNo: this.billNo
        })
        if (res.success) {
          this.result = true
          const flag = res.data.find((a) => a.isHold === true)
          if (!flag) {
            this.state = false
            this.billData = res.data
          } else {
            this.active = 2
            this.state = true
            this.billData = res.data
          }
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.log(error)
      }
    },
    handleSelectionChange(items) {
      this.selectionItem = items
    }
  }
}
</script>

<style lang="less" scoped>
.el-dialog__wrapper {
  .container {
    min-height: 460px;
    position: relative;
  }
  .top {
    width: 480px;
    height: 40px;
    background: #f7f7f7;
    border-radius: 4px 4px 4px 4px;
    position: absolute;
    top: -40px;
    left: 50%;
    transform: translateX(-50%);
    .step-content {
      height: 40px;
      background: #f7f7f7;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #eeeeee;
      margin: 0 auto;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #999999;
      box-sizing: border-box;
      .arrow {
        margin: 13px 15px;
      }
      .active {
        color: #2395f3;
      }
    }
  }
  .header {
    display: flex;
    width: 100%;
    padding: 20px 123px 0;
    box-sizing: border-box;
    /deep/ .searchbtn {
      width: 120px;
      background: #2395f3;
      color: #ffffff;
      border-radius: 0;
      border: 1px solid #2395f3;
      border-radius: 0px 6px 6px 0px;
    }
    .holdType {
      width: 100%;
      margin-top: 16px;
      font-size: 14px;
      font-weight: 400;
      color: #333333;
    }
    .holdNo {
      color: #2395f3;
    }
    .type {
      display: flex;
      justify-content: space-between;
      padding: 0 29px;
      box-sizing: border-box;
      margin-top: 16px;
      width: 450px;
      .hodeType-item {
        width: 120px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        background: #ffffff;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        border: 1px solid #EEEEEE;
        cursor: pointer;
        font-size: 14px;
        font-weight: 400;
        color: #999999;
      }
      .hodeActive {
        position: relative;
        border: 1px solid #2395f3;
        color: #2395f3;
        &::before {
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          border-top: 12px solid #2395f3;
          border-right: 12px solid #2395f3;
          border-left: 12px solid transparent;
          border-bottom: 12px solid transparent;
        }
        img {
          position: absolute;
          top: 2px;
          right: 2px;
          width: 11px;
          height: 10px;
        }
      }
    }
    .input-search {
      margin-top: 20px;
    }
  }
  .data {
    position: relative;
    .empty {
      position: absolute;
      top: 40px;
      left: 50%;
      transform: translateX(-50%);
    }
    .result {
      p {
        width: 100%;
        height: 20px;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        line-height: 20px;
        text-align: center;
        &:first-child {
          margin-top: 80px;
        }
        .billNo {
          color: rgba(35, 149, 243, 1);
        }
        &:last-child {
          margin-top: 20px;
        }
        .status {
          color: rgba(254, 69, 61, 1);
        }
      }
      .pager {
        text-align: left;
      }
      .pagerNum {
        color: rgba(91, 181, 255, 1);
      }
      .form {
        width: 480px;
        margin: 0 auto;
      }
    }
    .filter {
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 20px;
      margin-bottom: 10px;
      .bill-search {
        width: 240px;
      }
    }
    // .baseTable {

    // }
  }
  /deep/ .el-dialog__close {
    font-size: 24px;
  }
}
.footers {
  display: flex;
  justify-content: center;
  align-items: center;
  & div {
    width: 120px;
    height: 40px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    cursor: pointer;
  }
  .cancel {
    background: #EEEEEE;
    border-radius: 4px 4px 4px 4px;
    color: #999999;
    box-sizing: border-box;
  }
  .ok {
    background: #2395f3;
    margin-left: 20px;
    border-radius: 4px 4px 4px 4px;
    color: #ffffff;
  }
}
</style>
