<template>
  <el-dialog
    v-drag
    :visible.sync="dialogVisible"
    width="1190px"
    :show-close="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
    title="查看轨迹"
  >
    <div class="content">
      <div class="header">
        <div class="tab">
          <span
            v-if="billType !== 1"
            :class="['tabItem', active === 0 ? 'active' : '']"
            @click="changeType(0)"
          >船司轨迹</span>
          <span
            :class="['tabItem', active === 1 ? 'active' : '']"
            @click="changeType(1)"
          >清关轨迹</span>
        </div>
      </div>
      <div v-if="!active" class="data">
        <BaseTable
          height="460"
          :tableData="{
            ...tableData,
            fileds,
          }"
          :isPager="false"
          :border="true"
        ></BaseTable>
      </div>
      <div v-else class="panel">
        <el-row>
          <el-col :span="6">
            <div class="item">
              <div class="label">状态码</div>
              <span v-tooltip="160">{{ clearance.lastDispositionCode }}</span>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="item">
              <div class="label">主提单号</div>
              <span v-tooltip="160">{{ clearance.masterBLNo }}</span>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="item">
              <div class="label">分提单号</div>
              <span v-tooltip="160">{{ clearance.houseNo }}</span>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="item">
              <div class="label">件数</div>
              <span v-tooltip="160">{{ clearance.manifestQuantity }}</span>
            </div>
          </el-col>
        </el-row>
        <el-row style="margin-top: 15px;">
          <el-col :span="6">
            <div class="item">
              <div class="label">轨迹时间</div>
              <span v-tooltip="160">{{ clearance.lastEventDate | dateFilete }}</span>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="item">
              <div class="label">放行时间</div>
              <span v-tooltip="160">{{ clearance.releasedDate | dateFilete }}</span>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="item">
              <div class="label">更新时间</div>
              <span v-tooltip="160">{{ clearance.lastRunTime | dateFilete }}</span>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="item">
              <div class="label">扣件时间</div>
              <span v-tooltip="160">{{ clearance.holdDate | dateFilete }}</span>
            </div>
          </el-col>
        </el-row>
        <el-row style="margin-top: 15px;">
          <el-col :span="6">
            <div class="item">
              <div class="label">扣件解除日期</div>
              <span v-tooltip="160">{{ clearance.holdRemoveDate | dateFilete }}</span>
            </div>
          </el-col>
          <el-col :span="18">
            <div class="item" style="width: auto;">
              <div class="label">轨迹描述</div>
              <span v-tooltip="733">{{ clearance.statusDesc }}</span>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <span v-if="!active" slot="footer" class="footers">
      <div class="cancel flex_c_c" @click="cancel">取 消</div>
      <div class="ok flex_c_c" @click="cancel">确 定</div>
    </span>
    <span v-else slot="footer" class="footers">
      <el-button class="ok flex_c_c" icon="el-icon-refresh" @click="reload(1)">刷 新</el-button>
    </span>
  </el-dialog>
</template>

<script>
const BaseTable = () => import('@/components/table/BaseTable')
import { containerEvents, AMSQuery } from '@/api/basic'
export default {
  components: {
    BaseTable
  },
  filters: {
    dateFilete: function(date) {
      if (date) {
        return date.slice(0, date.length - 3)
      } else {
        return ''
      }
    }
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    type: {
      type: Number,
      default: 0
    },
    billType: {
      type: Number,
      default: 0
    },
    rowData: {
      type: Object,
      default: () => { return {} }
    }
  },
  data() {
    return {
      tableData: {
        columns: [],
        total: 0
      },
      pagerConfig: {
        pageIndex: 1,
        pageSize: 20
      },
      clearance: {},
      active: 0
    }
  },
  computed: {
    fileds() {
      const obj = {
        0: [
          {
              filed: 'bl_type',
              label: '状态类型',
              width: '140',
              resizable: true
            },
            {
              filed: 'issuer_code',
              label: '状态码',
              width: '80',
              resizable: true
            },
            {
              filed: 'message',
              label: '轨迹描述',
              width: '280',
              resizable: true
            },
            {
              filed: 'colorArgb',
              label: '轨迹时间',
              isCustom: true,
              width: '120',
              resizable: true
            },
            {
              filed: 'bl_no',
              label: '提单号',
              width: '120',
              resizable: true
            },
            {
              filed: 'master_bl_no',
              label: '主提单号',
              width: '120',
              resizable: true
            },
            {
              filed: 'manifest_quantity',
              label: '件数',
              width: '120',
              resizable: true
            },
            {
              filed: 'event_date',
              label: '更新时间',
              resizable: true
            }
          ],
        1: [
          {
              filed: 'eventLocation',
              label: '轨迹发生地',
              width: '260',
              resizable: true
            },
            {
              filed: 'eventCode',
              label: '状态码',
              width: '70',
              resizable: true
            },
            {
              filed: 'eventDesc',
              label: '轨迹描述',
              width: '240',
              resizable: true
            },
            {
              filed: 'eventDate',
              label: '轨迹时间',
              width: '',
              formatter: (row) => {
                if (row.eventDate) {
                  return row.eventDate.slice(0, row.eventDate.length - 3)
                } else {
                  return ''
                }
              },
              resizable: true
            },
            {
              filed: 'vesselName',
              label: '船名',
              width: '120',
              resizable: true
            },
            {
              filed: 'voyageNumber',
              label: '船号',
              width: '70',
              resizable: true
            },
            {
              filed: 'createdOn',
              label: '更新时间',
              formatter: (row) => {
                if (row.createdOn) {
                  return row.createdOn.slice(0, row.createdOn.length - 3)
                } else {
                  return ''
                }
              },
              resizable: true
            }
          ]
      }
      return obj[this.type]
    }
  },
  watch: {
    dialogVisible(val) {
      if (val) {
        this.getLists()
      }
    }
  },
  methods: {
    changeType(index) {
      this.active = index
      index && this.reload(0)
    },
    cancel() {
      this.active = 0
      this.$emit('close')
    },
    async getLists() {
      let params
      this.billType === 0 && (params = {
        carrierCode: this.rowData.mblNo.slice(0, 4),
        masterBLSerial: this.rowData.mblNo.slice(4, this.rowData.mblNo.length)
      })
      this.billType === 1 && (params = {
        mawbPrefix: this.rowData.mblNo.slice(0, 4),
        mawbSerial: this.rowData.mblNo.slice(4, this.rowData.mblNo.length)
      }, this.active = 1, this.reload(0))
      if (this.type === 1) {
        try {
          const result = await containerEvents(params)
          if (result.success) {
            this.tableData.columns = result.data.events
          } else {
            this.$message.error(result.errorMessage)
          }
        } catch (error) {
          console.error(error)
        }
      } else {
        try {
          const result = await AMSQuery({ ...params, mot: this.billType ? 'A' : 'O' })
          result.success && (this.clearance = result.data)
          !result.success && this.$message.error(result.errorMessage)
        } catch (error) {
          console.log(error)
        }
      }
    },
    async reload(type) {
      try {
          const params = {
            mawbPrefix: this.rowData.mblNo.slice(0, 4),
            mawbSerial: this.rowData.mblNo.slice(4, this.rowData.mblNo.length)
          }
          const result = await AMSQuery({ ...params, mot: this.billType ? 'A' : 'O' }, true)
          if (result.success) {
            this.clearance = result.data
            type && this.$message.success('刷新成功')
          }
          !result.success && type && this.$message.error(result.errorMessage)
        } catch (error) {
          console.log(error)
        }
    }
  }
}
</script>

<style scoped lang="less">
.content {
  position: relative;
  width: 100%;
  height: auto;
  margin: 0 auto;
  .basic {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 188px;
    margin-bottom: 20px;
    .basic-item {
      margin-right: 20px;
      margin-bottom: 20px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #666666;
    }
    .label {
      height: 19px;
      line-height: 19px;
      margin-bottom: 5px;
    }
    .info {
      width: 200px;
      height: 40px;
      line-height: 40px;
      background: #F7F7F7;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #EEEEEE;
      text-indent: 10px;
    }
  }
  .header {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding: 0 20px;
    box-sizing: border-box;
    .tabItem {
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      line-height: 20px;
      margin-left: 20px;
      cursor: pointer;
    }
    .active {
      color: #2395f3;
    }
  }
  .data {
    padding: 0 20px;
    box-sizing: border-box;
    &:deep(.fTable) {
      .el-table--border {
        border-radius: 4px 4px 4px 4px;
      }
      .fp-cell {
        border: 0;
      }
    }
  }
  .panel {
    width: 100%;
    height: 460px;
    border-top: 1px solid #F7F7F7;
    padding: 20px 20px 0;
    box-sizing: border-box;
    .item {
      width: 260px;
      height: 30px;
      display: flex;
    }
    .label {
      width: 100px;
      height: 30px;
      background: #F7F7F7;
      border-radius: 4px 4px 4px 4px;
      opacity: 1;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #666666;
      line-height: 30px;
      text-align: center;
    }
    span {
      display: inline-block;
      flex: 1;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      line-height: 30px;
      text-indent: 15px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
.footers {
  width: 100%;
  display: flex;
  justify-content: center;
  .cancel {
    width: 120px;
    height: 40px;
    background: #EEEEEE;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    margin-right: 16px;
    cursor: pointer;
  }
  .ok {
    width: 120px;
    height: 40px;
    background: #2395f3;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    cursor: pointer;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
}
/deep/ .el-dialog__header {
  border: 0;
}
/deep/ .el-dialog__body {
  padding-top: 0;
}
</style>
