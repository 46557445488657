<template>
  <el-dialog
    v-drag
    title="新增跟进记录"
    :visible.sync="dialogVisible"
    width="690px"
    :append-to-body="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="cancel"
    top="5vh"
  >
    <div class="content">
      <el-form ref="form" class="form" :model="form" :rules="rules">
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item prop="mblSendWayId" label="派送方式">
              <el-select
                v-model="form.mblSendWayId"
                style="width: 315px"
                placeholder="请选择类别"
              >
                <el-option
                  v-for="(item, $index) in sandWayOptions"
                  :key="$index"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item prop="type" label="类型">
              <el-select
                v-model="form.type"
                style="width: 315px"
                placeholder="请选择类型"
              >
                <el-option
                  v-for="(item, $index) in config.sandWayTypeOptions"
                  :key="$index"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-form-item prop="remark" label="描述">
            <el-input v-model="form.remark" placeholder="请输入描述" style="width: 650px;" />
          </el-form-item>
        </el-row>
        <div class="table">
          <div class="search">
            <span class="label">选择订单</span>
            <el-input
              v-model="text"
              style="width: 240px;"
              placeholder="请输入内容"
              prefix-icon="el-icon-search">
            </el-input>
          </div>
          <BaseTable
            style="margin-top: 10px;"
            height="360"
            :tableData="{...tableData, columns}"
            :isPager="false"
            :border="true"
            @handleSelectionChange="handleSelectionChange"
          ></BaseTable>
        </div>
        <div class="table-footer">
          <span>已选择  <span style="color: rgba(35, 149, 243, 1);">{{ selection.length }}</span>  项</span>
        </div>
      </el-form>
    </div>
    <span slot="footer" class="footers">
      <div class="cancel flex_c_c" @click="cancel">取 消</div>
      <div class="ok flex_c_c" @click="ok('form')">确 定</div>
    </span>
  </el-dialog>
</template>

<script>
import { getMBLSendWay, getOrderDetailPages, addFMSMBLFollowUp } from '@/api/bill'
const BaseTable = () => import('@/components/table/BaseTable')
import config from '@/utils/config'
export default {
  components: { BaseTable },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    rowData: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  data() {
    return {
      config,
      sandWayOptions: [],
      form: {
        mblSendWayId: '',
        remark: '',
        type: ''
      },
      rules: {
        mblSendWayId: [{ required: true, message: '请选择派送方式', trigger: 'change' }],
        type: [{ required: true, message: '请选择类型', trigger: 'change' }],
        remark: [{ required: true, message: '请输入描述', trigger: 'blur' }]
      },
      tableData: {
        fileds: [
          {
            filed: 'referenceNumber',
            label: '参考单号',
            width: '',
            resizable: true
          },
          {
            filed: 'masterTrackingNumber',
            label: '主单号',
            width: '',
            resizable: true
          },
          {
            filed: 'trackingNumber',
            label: '子单号',
            resizable: true
          }
        ],
        columns: [],
        total: 0,
        mutiCheck: true
      },
      selection: [],
      text: ''
    }
  },
  computed: {
    columns: function() {
        if (!this.text) {
            return this.tableData.columns
        }
        return this.tableData.columns.filter(item => {
            return item.referenceNumber === this.text.toUpperCase() ||
            item.masterTrackingNumber === this.text.toUpperCase() ||
            item.trackingNumber === this.text.toUpperCase() ||
            item.referenceNumber.split('-') === this.text.toUpperCase()
        })
    }
  },
  watch: {
    dialogVisible(val) {
      if (val) {
        this.getList()
        this.getOrder()
      }
    }
  },
  created() {
    // this.getList()
    // this.getOrder()
  },
  methods: {
    cancel() {
      this.$emit('cancel')
    },
    async getList() {
      try {
        const res = await getMBLSendWay({
          mblId: this.rowData.id || '442456957047493'
        })
        if (res.success) {
          this.sandWayOptions = res.data.map((item) => {
            return {
              value: item.id,
              label: `${item.typeName} ${item.code ? `- ${item.code}` : ''}`
            }
          })
        }
        console.log(res)
      } catch (error) {
        console.log(error)
      }
    },
    async getOrder() {
        try {
         const params = {
            pageIndex: 1,
            pageSize: 1000,
            queryParam: {
                id: this.rowData.id || '442456957047493'
            }
        }
            const result = await getOrderDetailPages(params)
            if (result.success) {
                this.tableData.columns = result.data.list
                this.tableData.total = result.data.total
            } else {
                this.$message.error(result.errorMessage)
            }
        } catch (error) {
            console.log(error)
        }
    },
    ok(formName) {
      this.$refs[formName].validate(async(valid) => {
        if (valid) {
            try {
                const res = await addFMSMBLFollowUp({
                    ...this.form,
                    trackingNumber: this.selection.map(a => a.trackingNumber)
                })
                if (res.success) {
                    this.$message.success('新增成功')
                    this.$emit('ok')
                } else {
                    this.$message.error(res.errorMessage)
                }
            } catch (error) {
                console.log(error)
            }
          console.log(valid)
        }
      })
    },
    handleSelectionChange(selection) {
        this.selection = selection
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  width: 100%;
  height: auto;
  margin: 0 auto;
  .form {
    width: 100%;
    margin: 0 auto;
    &:deep(.el-form-item__label) {
        line-height: 30px;
        font-size: 14px;
      }
      &:deep(.el-form-item) {
        margin-bottom: 10px;
        position: relative;
      }
  }
  .table {
    width: 100%;
    margin: 20px auto 0;
    height: 410px;
    .search {
       height: 40px;
       width: 100%;
       display: flex;
       justify-content: space-between;
    }
    .label {
        margin-top: 20px;
        width: 56px;
        height: 20px;
        font-size: 14px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        line-height: 20px;
    }
  }
  .table-footer {
    width: 76px;
    height: 20px;
    font-size: 12px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    line-height: 20px;
    margin-top: 10px;
  }
}
.footers {
  width: 100%;
  display: flex;
  justify-content: center;
  .cancel {
    width: 120px;
    height: 40px;
    background: #eeeeee;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    margin-right: 16px;
    cursor: pointer;
  }
  .ok {
    width: 120px;
    height: 40px;
    background: #2395f3;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    cursor: pointer;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
}
/deep/ .el-dialog__body {
  padding: 0px 20px 40px;
}
/deep/ .el-dialog__header {
  border: 0;
}
</style>
